export function getSearchWidget(container = '[data-widget="searchbox"]', placeholder = 'Rechercher par mot clé') {
    return window.instantsearch.widgets.searchBox({
        container,
        placeholder,
        templates: {
            submit({ cssClasses }, { html }) {
                return html`
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.1047 14.2002L16.999 17.0002M16.0657 9.53358C16.0657 13.1418 13.1406 16.0669 9.53236 16.0669C5.9241 16.0669 2.99902 13.1418 2.99902 9.53358C2.99902 5.92532 5.9241 3.00024 9.53236 3.00024C13.1406 3.00024 16.0657 5.92532 16.0657 9.53358Z" stroke="#1A1F2F" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                `;
            },
        },
    });
}
