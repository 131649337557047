import { collapseButtonText } from './panel.js';
import { getLocalMonth } from '../helpers.js';

const startDateRefinementList = window.instantsearch.widgets.panel({
    templates: {
        header() {
            return 'Dates de début';
        },
        collapseButtonText,
    },
    collapsed: () => false,
})(window.instantsearch.widgets.refinementList);
export const getStartDateFilter = (containerId = '[data-widget="start-date"]') => {
    return startDateRefinementList(
        {
            container: containerId,
            attribute: 'monthStartTs',
            sortBy: ['name:asc'],
            transformItems(items) {
                // Current date
                const currentDate = new Date();
                const currentDateYear = currentDate.getFullYear();
                const currentDateMonth = currentDate.getMonth();
                const localMonthStart = new Date(`${currentDateYear}-${currentDateMonth}-01`);

                return items
                .map(item => {
                    const timeMs = parseInt(item.label, 10) * 1000;
                    // Item date
                    const date = new Date(timeMs);
                    // Month label (short)
                    const formattedMonthLabel = `${getLocalMonth(date)} ${date.getFullYear()}`;

                    return {
                        ...item,
                        label: formattedMonthLabel,
                        value: item.value, // Original value
                        highlighted: formattedMonthLabel,
                        dateObj: date, // Date object for sorting && filtering
                    };
                })
                // Keep only futures dates
                .filter(item => item.dateObj.getTime() >= localMonthStart.getTime())
                // Sort by asc
                .sort((a, b) => a.dateObj - b.dateObj);
            },
            limit: 8 // Show the 8 futures months, included current month
        }
    )
}
