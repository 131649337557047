const paginationWithMultiplePages = window.instantsearch.widgets.panel({
    hidden ({ results }) {
        return results.nbPages <= 1
    },
})(window.instantsearch.widgets.pagination)

export const getPagination = (scrollTo = false, containerId = '[data-widget="pagination"]') => {
    return paginationWithMultiplePages({
        container: containerId,
        scrollTo,
        padding: 2,
        showFirst: false,
        showLast: false,
        templates: {
            previous: (_, { html }) => {
                return html`
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 1L1 7L7 13" stroke="#1A1F2F"
                              stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                `
            },
            next: (_, { html }) => {
                return html`
                    <svg width="8" height="14" viewBox="0 0 8 14" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 13L7 7L1 1" stroke="#1A1F2F"
                              stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round"/>
                    </svg>
                `
            },
        },
    })
}
