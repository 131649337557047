import NavigationSingleProduct from "../components/navigation-single-product";
import ProductAside from "../components/product-aside";
import ProductDownloadProgram from "../components/product-download-program";
import ShowHideContact from "../components/show-hide-contact";
export default {
    init() {
        console.log('init single product.js');
        const productaside = new ProductAside();
        const navsingleproduct = new NavigationSingleProduct
        const productdownloadprogram = new ProductDownloadProgram
        const showhidecontact = new ShowHideContact
        productaside.init();
        productdownloadprogram.init()
    },
};
