export default class Search {
    init () {
        // Click on main search input
        $('form.banner-top-search > button, form.banner-top-search + button').on('click', (e) => {
            e.preventDefault && e.preventDefault();

            this.showSearchLayer();
        })
    }

    showSearchLayer() {
        const searchLayerEl = document.querySelector('main-search')
        searchLayerEl && searchLayerEl.openSearchLayer();
    }
}
