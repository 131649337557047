export default class KeyNumbers {
    constructor() {
        this.observer = null; // Initialisation de l'observateur
    }

    init() {
        // Créer un Intersection Observer pour surveiller quand chaque bloc devient visible
        this.observer = new IntersectionObserver(this.onIntersection.bind(this), {
            root: null,        // La racine est la fenêtre du navigateur
            threshold: 0.1     // Déclenchement lorsque 10% du bloc est visible
        });

        // Sélectionner et observer tous les blocs avec la classe .fd-block-key-numbers
        const blocks = document.querySelectorAll('.fd-block-key-numbers');
        blocks.forEach(block => {
            this.observer.observe(block); // Observer chaque bloc individuellement
        });
    }

    onIntersection(entries) {
        // Cette méthode est appelée lorsque l'état de visibilité des blocs observés change
        entries.forEach(entry => {
            if (entry.isIntersecting) { // Si le bloc est visible dans le viewport
                this.animateNumbers(entry.target); // Démarrer l'animation des nombres pour ce bloc
                this.observer.unobserve(entry.target); // Arrêter d'observer ce bloc après l'animation
            }
        });
    }

    animateNumbers(block) {
        // Animer les nombres dans le bloc spécifié
        const numbers = block.querySelectorAll('.key-number');
        numbers.forEach(numberElement => {
            const text = numberElement.textContent.trim(); // Obtenir la valeur initiale du texte
            const finalValue = parseFloat(text.replace(/\s+/g, '').replace(',', '.')); // Convertir la valeur en nombre
            const duration = 2000; // Durée de l'animation en millisecondes

            numberElement.textContent = '0'; // Initialiser le texte à '0' avant l'animation

            // Utiliser jQuery pour animer le texte du nombre
            $(numberElement).animate({ num: finalValue }, {
                duration,
                step: function (now) { // À chaque étape de l'animation
                    numberElement.textContent = numberWithThousandsSeparator(Math.floor(now)); // Ajouter séparateurs de milliers
                },
                complete: function () { // À la fin de l'animation
                    numberElement.textContent = numberWithThousandsSeparator(finalValue); // Afficher la valeur finale avec séparateurs de milliers
                }
            });
        });
    }
}

// Fonction pour ajouter les séparateurs de milliers
function numberWithThousandsSeparator(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
