export default class Forms {

    init() {
        this.initializeSelect();
        console.log('init forms.js');
    }

    initializeSelect() {
        let select = document.querySelectorAll('.gfield_select');
        if (select.length > 0) {
            select.forEach((item) => {
                if(item.value === '') {
                    item.classList.add('gf_placeholder');
                }

                item.addEventListener('change', function(e) {
                    console.log('item.value', item.value)
                    if(item.value !== '') {
                        item.classList.remove('gf_placeholder');
                    } else {
                        item.classList.add('gf_placeholder');
                    }
                });

                item.addEventListener('click', function(e) {
                    item.classList.toggle('gfield_select_open')
                });
            });
        }
    }

    finalize() {
        console.log('finalize forms.js');
    }
}
