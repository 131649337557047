import { collapseButtonText } from './panel.js';

const placesRefinementList = window.instantsearch.widgets.panel({
    templates: {
        header() {
            return 'Lieux';
        },
        collapseButtonText,
    },
    collapsed: () => false,
})(window.instantsearch.widgets.refinementList);

export const getPlacesFilter = (containerId = '[data-widget="places"]') => {
    return placesRefinementList(
        {
            container: containerId,
            attribute: 'place',
            sortBy: ['name']
        }
    )
}
