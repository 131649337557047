import Header from "../components/header";
import StickyBlock from '../components/sticky-block.js';
import Temoignage from "../components/temoignage";
import KeyNumbers from "../components/KeyNumbers";
import Clients from "../components/clients";
import CarouselCtaExtended from "../components/carouselCtaExtended";
import Videos from "../components/videos.js";
import KeyNumbersVisual from "../components/keynumbervisual.js";
import VoirPlusImageText from "../components/voir-plus-text-image.js";
import VoirPlusText from "../components/voir-plus-text.js";
import FaqFilter from "../components/faq-filter.js";
import AlgoliaSearch from '../components/algolia-search/algolia-search.js';
import Forms from '../components/forms.js';
import Cart from '../components/cart.js';
import CheckoutUpdateOrder from "../components/checkoutUpdateOrder.js";
import Bandeau from "../components/bandeau.js";
export default {
    init() {
      console.log('init common.js');
    },

    finalize() {
        let header = new Header();
        let carouselCtaExtended = new CarouselCtaExtended;
        let temoignage = new Temoignage;
        let keyNumbers = new KeyNumbers;
        let clients = new Clients;
        let videos = new Videos;
        let keynumbersviual = new KeyNumbersVisual;
        let voirplustextimage = new VoirPlusImageText;
        let voirplustext = new VoirPlusText();
        let faqfilter = new FaqFilter();
        let forms = new Forms();
        let bandeau  = new Bandeau();
        // let cart = new Cart();
        jQuery(Cart);
        jQuery(CheckoutUpdateOrder);

        header.init();
        carouselCtaExtended.init();
        temoignage.init();
        keyNumbers.init();
        clients.init();
        videos.init();
        keynumbersviual.init();
        voirplustextimage.init();
        voirplustext.init()
        faqfilter.init()
        voirplustext.init();
        forms.init();
        // cart.init();

        let stickyBlock = new StickyBlock();
        console.log('finalize common.js')
    },
};
