export default class KeyNumbersVisual {
    constructor() {
        this.keynuberviualblocks = document.querySelectorAll('.wp-block-chiffres-cles-avec-visuels');
        this.init();
    }

    init() {
        console.log('keynumbervisual.init');
        if(this.keynuberviualblocks.length) {
            this.keynuberviualblocks.forEach((elt, index) => {
                const keynumbervisual = new Swiper('.wp-block-chiffres-cles-avec-visuels .swiper-key-number', {
                    slidesPerView: 'auto',
                    spaceBetween: 20,
                    grabCursor: true,
                    loop: true,
                    centeredSlides: true,
                    speed: 4000,
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: true,
                    },

                    breakpoints: {
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 48,
                            centeredSlides: false,
                            loop: false,
                            autoplay: false,
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 48,
                            centeredSlides: false,
                            loop: false,
                            autoplay: false,
                        },
                    }
                })
            })
        }
    }
}
