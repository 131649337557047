import {
    getSearchWidget,
    getCoursesResult,
    getConfigureWidget,
    getThematicsFilter,
    getDomainsFilter,
    getCourseTypesFilter,
    getStartDateFilter,
    getDurationFilter,
    getPlacesFilter,
    getModalitiesFilter,
    getQualificationFilter,
    getStatsWidget,
    getCurrentRefinementsWidget,
    getPagination,
    getRouting,
    searchUiState
} from './widgets';
import { initHeaderHeightUpdater, handleWindowResize } from './helpers.js'

export default class AlgoliaSearch extends HTMLElement {
    constructor() {
        super();
        this.firstRender = false;
        this.currentRefinementEl = null;
        this.currentRefinementElMobile = null;
        this.search = null;
        this.filtersContainerEl = null;
        this.inputSearch = null;
        this.paginationScrollToEl = false;
    }

    buildWidgets (searchInst) {
        const searchBoxEl = this.querySelector('[data-widget="searchbox"]');
        const hitsContainer = this.querySelector('[data-widget="hits"]');
        const thematicsFilterEl = this.querySelector('[data-widget="thematics"]');
        const domainsFilterEl = this.querySelector('[data-widget="domains"]');
        const courseTypesFilterEl = this.querySelector('[data-widget="course-types"]');
        const durationFilterEl = this.querySelector('[data-widget="duration"]');
        const startDateFilterEl = this.querySelector('[data-widget="start-date"]');
        const placesFilterEl = this.querySelector('[data-widget="places"]');
        const modalitiesFilterEl = this.querySelector('[data-widget="modalities"]');
        const qualificationFilterEl = this.querySelector('[data-widget="qualification"]');
        const paginationEl = this.querySelector('[data-widget="pagination"]');
        const statsEl = this.querySelector('[data-widget="stats"]');
        const widgets = [getConfigureWidget()];

        searchBoxEl && widgets.push(getSearchWidget(searchBoxEl));
        hitsContainer && widgets.push(getCoursesResult(hitsContainer));
        thematicsFilterEl && widgets.push(getThematicsFilter(thematicsFilterEl));
        domainsFilterEl && widgets.push(getDomainsFilter(domainsFilterEl));
        startDateFilterEl && widgets.push(getStartDateFilter(startDateFilterEl));
        courseTypesFilterEl && widgets.push(getCourseTypesFilter(courseTypesFilterEl));
        durationFilterEl && widgets.push(getDurationFilter(durationFilterEl));
        placesFilterEl && widgets.push(getPlacesFilter(placesFilterEl));
        modalitiesFilterEl && widgets.push(getModalitiesFilter(modalitiesFilterEl));
        qualificationFilterEl && widgets.push(getQualificationFilter(qualificationFilterEl));
        statsEl && widgets.push(getStatsWidget(statsEl));
        paginationEl && widgets.push(getPagination(this.paginationScrollToEl, paginationEl));
        this.currentRefinementEl && widgets.push(getCurrentRefinementsWidget(this.currentRefinementEl));
        this.currentRefinementElMobile && widgets.push(getCurrentRefinementsWidget(this.currentRefinementElMobile));

        widgets.length && searchInst.addWidgets(widgets);
    }

    clearSearchRefinements() {
        this.search.helper
            .setQuery('')
            .clearRefinements()
            .search();
    }

    getClearRefinementsButton() {
        const li = document.createElement('li');
        const button = document.createElement('button');
        const text = document.createElement('span');

        text.innerText = 'Tout effacer';
        button.appendChild(text);
        button.onclick = () => {
            this.clearSearchRefinements();
        }
        li.setAttribute('class', 'clear-all')
        li.appendChild(button);

        return li;
    }

    onSearchRender() {
        if (this.currentRefinementEl && this.currentRefinementElMobile) {
            const refinementsListEl = [];
            refinementsListEl.push(this.currentRefinementEl.querySelector('ul.ais-CurrentRefinements-list'));
            refinementsListEl.push(this.currentRefinementElMobile.querySelector('ul.ais-CurrentRefinements-list'));

            refinementsListEl.forEach(listEl => {
                if (listEl) {
                    const clearButtonEl = listEl.querySelector('li.clear-all');

                    !clearButtonEl && listEl.appendChild(this.getClearRefinementsButton());
                }
            });
        }
        if (!this.firstRender) {
            this.firstRender = true;
            setTimeout(() => {
                this.initMobileResultSectionObserver();
                this.inputSearch = this.querySelector('input.ais-SearchBox-input');
                this.inputSearch && this.inputSearch.focus();

                this.querySelectorAll('.hide').forEach(el => {
                    el.classList.remove('hide');
                });

                this.querySelectorAll('.loader-wrapper').forEach(el => {
                    el.classList.add('hide');
                })

                // Hide skeletons
                this.querySelectorAll('.skeleton-wrapper').forEach(el => {
                   el.remove();
                });
            }, 2000);
        }
    }

    // On mobile if this section is in the viewport
    // show the "show filters" button
    initMobileResultSectionObserver() {
        const resultContainerEl = this;
        const mobileFilterButtonEl = this.querySelector('.show-filters-button-container');

        this.filtersContainerEl = this.querySelector('.sub-filters');

        if (resultContainerEl && mobileFilterButtonEl && this.filtersContainerEl) {
            const filtersInnerWrapperEl = this.filtersContainerEl.querySelector('.inner-wrapper');
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        mobileFilterButtonEl.classList.add('show');
                    } else {
                        mobileFilterButtonEl.classList.remove('show');
                    }
                });
            }, {threshold: 0.05});
            const closeFiltersContainerEl =  this.filtersContainerEl.querySelector('button.close');
            const showResultButtonContainerEl =  this.filtersContainerEl.querySelector('.apply-filters-button-container > button');

            [
                closeFiltersContainerEl,
                mobileFilterButtonEl,
            ].forEach(item => {
                item && item.addEventListener('click', this.onShowFilters.bind(this));
            })

            showResultButtonContainerEl?.addEventListener('click', this.onShowResult.bind(this))

            observer.observe(resultContainerEl);
            filtersInnerWrapperEl.addEventListener('scroll', () => {
                filtersInnerWrapperEl.classList.toggle('shadowed', !!filtersInnerWrapperEl.scrollTop);
            });
        }
    }

    onShowResult() {
        this.onShowFilters();
        if (this.paginationScrollToEl) {
            this.paginationScrollToEl.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }

    // Show filters on mobile
    onShowFilters() {
        this.filtersContainerEl.classList.toggle('show');
        $('html, body').toggleClass('open');
    }

    // Mobile : handle on close
    onCloseFilters() {

    }

    // Build routing
    buildRouting(thematicsString, indexName, basePath) {
        try {
            const thematics = JSON.parse(thematicsString);
            if (thematics && thematics.length) {
                return getRouting(indexName, thematics, basePath);
            }
        } catch (e) {
            console.warn('Parse thematics error: ', e);
        }

        return true;
    }

    buildInstantSearch(appId, appKey, indexName, routing = true) {
        try {
            this.search = window.instantsearch({
                searchClient: window.algoliasearch(appId, appKey),
                indexName,
                insights: true,
                routing
            });
            const syncMiddleware = () => {
                return {
                    onStateChange({ uiState }) {
                        console.log('--- UI STATE --', uiState);
                        searchUiState.setState(uiState);
                    }
                };
            };

            this.search.use(syncMiddleware);
            this.currentRefinementEl = this.querySelector('.results-container div[data-widget="current-refinements"]');
            this.currentRefinementElMobile = this.querySelector('.sub-filters div[data-widget="current-refinements"]');
            this.buildWidgets(this.search);

            this.search.on('render', this.onSearchRender.bind(this));
            this.search.start();

            initHeaderHeightUpdater();
            handleWindowResize()
        } catch (e) {
            console.warn('Error while initializing instantsearch', e);
        }
    }

    /**
     * Initialisation
     */
    init() {
        const {
            appId,
            appKey,
            indexName,
            thematics,
            basePath
        } = this.dataset;
        const routing = this.buildRouting(thematics, indexName, (basePath || 'formation'));

        this.paginationScrollToEl = this.querySelector('#scroll-top-mark');
        appId && appKey && indexName && this.buildInstantSearch(appId, appKey, indexName, routing);
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        })
    }
}
