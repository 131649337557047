import intlTelInput from 'intl-tel-input';
import {fr} from "intl-tel-input/i18n";

export default {
    itiInput: null,

    init() {
        console.log('init checkout.js');

        const inputTel = document.querySelector("#billing_phone_1");
        if (inputTel) {
            this.itiInput = intlTelInput(inputTel, {
                countrySearch: false,
                initialCountry: "auto",
                i18n: fr,
                separateDialCode: true,
                geoIpLookup: callback => {
                    fetch("https://ipapi.co/json")
                        .then(res => res.json())
                        .then(data => callback(data.country_code))
                        .catch(() => callback("us"));
                },
                loadUtils: () => import("intl-tel-input/utils"),
            });

            const inputTelFull = document.querySelector("#billing_phone");
            if (inputTelFull && inputTelFull.value.trim() !== '') {
                this.itiInput.setNumber(inputTelFull.value);
            }

            $("#billing_phone_1").on('blur change', (e) => {
                const $input = $(e.currentTarget);
                const $wrapper = $input.closest('.gfield--type-tel');

                if ($input.val().trim() !== '' && this.itiInput.isValidNumber()) {
                    $(inputTelFull).val(this.itiInput.getNumber());
                    $('.gfield_error_message', $wrapper).empty();
                } else {
                    $('.gfield_error_message', $wrapper).text('Numero de téléphone invalide');
                    //console.log('invalid number');
                }
            });
        }


        $('input[name="ship_to_different_address_1"]').on('change', (e) => {
            let $this = $(e.currentTarget);
            let $shippingFields = $('.shipping_address');
            let $differentShippingField = $('#ship-to-different-address-checkbox');

            if (1 == parseInt($this.val())) {
                $shippingFields.show();
                $differentShippingField.prop('checked', true);
            } else {
                $shippingFields.hide();
                $differentShippingField.prop('checked', false);
            }
            console.log('init', parseInt($('input[name="ship_to_different_address_1"]:checked').val()));

            this.validateFormsCheckout();
        });

        $('#customer_details input ').not('[type=radio]').on('change blur', () => {
            this.validateFormsCheckout();
        });

        $('#customer_details #billing_country ').on('change blur', () => {
            this.validateFormsCheckout();
        });

        $('.checkout-infos-content .btn-validate').on('click', (e) => {

            e.preventDefault();
            let $this = $(e.currentTarget);

            if ($this.hasClass('disabled')) {
                return;
            }

            if (!this.itiInput.isValidNumber()) {
                $('.gfield_error_message', '.gfield--type-tel').text('Numero de téléphone invalide');
                return
            }

            $(".checkout-infos-order").addClass('open-order');
            $('.checkout-infos-content').addClass('infos-is-valid');
        });

        $('.btn-aside-open').on('click', () => {
            $('body, html').toggleClass('aside-open');
            return false;
        })
    },

    validateForm($form) {
        let isValid = true;
        let inputs = $('input', $form).not('[type=submit], [type=hidden]');
        let selects = $('select', $form);

        inputs.each((i, input) => {
            // console.log( $(input).attr('name'), input.value );
            isValid = input.value.trim() != '';

            return isValid;
        });

        if (isValid) {
            selects.each((i, select) => {
                isValid = $(select).val() != '';
            });
        }

        return isValid
    },

    validateFormsCheckout() {
        let isValid = false;

        if (parseInt($('input[name="ship_to_different_address"]:checked').val()) == 1) {
            isValid = this.validateForm($('.woocommerce-billing-fields')) && this.validateForm($('.shipping_address'));
        } else {
            isValid = this.validateForm($('.woocommerce-billing-fields'));
        }


        if (isValid) {
            $('#customer_details .btn').removeClass('disabled');
        } else {
            $('#customer_details .btn').addClass('disabled');
        }
    },

    finalize () {
        this.validateFormsCheckout();
    }
}
