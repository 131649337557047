export default class CarouselCtaExtended {
    constructor() {
        this.carouselCtas = document.querySelectorAll('.fd-block-carousel-cta');
        this.init();
    }

    init() {
        if (this.carouselCtas.length) {
            this.carouselCtas.forEach((elt, index) => {
                const swiperElementElearning = elt.querySelector('.e-learning-list .swiper-carousel-cta, .presse-list .swiper-carousel-cta');
                const swiperElementFormation = elt.querySelector('.formation-list .swiper-carousel-cta');


                if (swiperElementElearning) {
                    const pagination = elt.querySelector('.swiper-pagination');
                    pagination.id = `swiper-pagination-cta-${index}`;

                    const swiper = new Swiper(swiperElementElearning, {
                        slidesPerView: 1.01,
                        centeredSlides: false,
                        spaceBetween: 5,
                        grabCursor: true,
                        dynamicBullets: true,
                        pagination: {
                            el: `#swiper-pagination-cta-${index}`,
                            type: 'bullets',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: elt.querySelector('.swiper-next'),
                            prevEl: elt.querySelector('.swiper-prev'),
                        },
                        breakpoints: {
                            768: {
                                slidesPerView: 2.01,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3.01,
                                spaceBetween: 20,
                            },
                            1280: {
                                slidesPerView: 4.01,
                                spaceBetween: 20,
                            },
                        },
                    });


                    swiper.on('slideChange', function () {
                        const activeIndex = swiper.realIndex;
                        const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');

                        bullets.forEach((bullet) => bullet.classList.remove('swiper-pagination-bullet-active'));

                        if (bullets[activeIndex]) {
                            bullets[activeIndex].classList.add('swiper-pagination-bullet-active');
                        }
                    });
                }

                if (swiperElementFormation) {
                    const pagination = elt.querySelector('.swiper-pagination');
                    pagination.id = `swiper-pagination-cta-${index}`;

                    const swiper = new Swiper(swiperElementFormation, {
                        slidesPerView: 1.01,
                        centeredSlides: false,
                        spaceBetween: 5,
                        grabCursor: true,
                        dynamicBullets: true,
                        pagination: {
                            el: `#swiper-pagination-cta-${index}`,
                            type: 'bullets',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: elt.querySelector('.swiper-next'),
                            prevEl: elt.querySelector('.swiper-prev'),
                        },
                        breakpoints: {
                            768: {
                                slidesPerView: 2.01,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 3.01,
                                spaceBetween: 20,
                            },
                        },
                    });


                    swiper.on('slideChange', function () {
                        const activeIndex = swiper.realIndex;
                        const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');

                        bullets.forEach((bullet) => bullet.classList.remove('swiper-pagination-bullet-active'));

                        if (bullets[activeIndex]) {
                            bullets[activeIndex].classList.add('swiper-pagination-bullet-active');
                        }
                    });
                }
            });
        }
    }
}
