export default class FaqFilter {
    constructor() {
        this.faqFilterItems = document.querySelectorAll('.faq-filters-item');
        this.textContentItems = document.querySelectorAll('.text-content-item');
        this.faqBlocks = document.querySelectorAll('.fd-block-content');
        this.mobileFilter = document.querySelector('.faq-filters.mobile');

    }

    init() {
        if (this.faqFilterItems.length) {
            this.setupDesktopFilter();
        }

        if (this.mobileFilter) {
            this.setupMobileFilter();
        }

        const firstFilter = this.faqFilterItems[0] || this.mobileFilter?.options[0];

        if (firstFilter) {
            const defaultId = firstFilter.id || firstFilter.value;
            this.setActiveFilter(firstFilter);
            this.filterContent(defaultId);
        }
    }

    setupDesktopFilter() {
        this.faqFilterItems.forEach((filter) => {
            filter.addEventListener('click', () => {
                this.setActiveFilter(filter);
                this.filterContent(filter.id);
            });
        });
    }

    setupMobileFilter() {
        this.mobileFilter.addEventListener('change', (e) => {
            const selectedValue = e.target.value;
            this.filterContent(selectedValue);

            this.faqFilterItems.forEach((item) => {
                if (item.id === selectedValue) {
                    this.setActiveFilter(item);
                }
            });
        });
    }

    setActiveFilter(activeItem) {
        this.faqFilterItems.forEach((item) => item.classList.remove('active'));
        activeItem.classList.add('active');
    }

    filterContent(selectedId) {
        this.faqBlocks.forEach((block) => {
            const thematics = block.dataset.thematic.split(' ');
            block.style.display = thematics.includes(selectedId) ? 'grid' : 'none';
        });
    }
}
