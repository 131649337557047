/*
* Function executed in jquery context
 */
export default function ($) {
    /**
     * Update cart item quantity
     * @param itemKey
     * @param newQty
     */
    const updateCart = (itemKey, newQty)=> {
        const isPageCart = $('.page-cart-content').length > 0;
        const data = {
            action: 'woocommerce_update_cart_item',
            cart_item_key: itemKey,
            new_qty: newQty,
            page_cart: isPageCart
        };

        // return true;
        // Add class to cart to indicate loading
        $('.woocommerce-mini-cart-wrapper, .page-cart-content').addClass('updating-cart');

        $.ajax({
            type: 'POST',
            url: wc_add_to_cart_params.ajax_url,
            data: data,
            success: function(response) {
                // Trigger a fragment refresh to update cart content
                // console.log(response);

                if (!isPageCart) {
                    $(document.body).trigger('wc_fragment_refresh');
                    // Remove the updating class
                    $('.woocommerce-mini-cart-wrapper').removeClass('updating-cart');
                } else {
                    if (response && response.fragments) {
                        console.log(response.fragments);
                        const fragments = response.fragments;
                        $.each(fragments, function (key, value) {
                            $(key).html(value);
                        });
                        $('.page-cart-content').removeClass('updating-cart');
                    }
                }

            },
            error: function() {
                // Remove the updating class in case of error as well
                $('.woocommerce-mini-cart-wrapper').removeClass('updating-cart');
            }
        });
    }

    /**
     * Add item to cart
     * @param _productId
     */
    const addToCart = (_productId) => {
        var data = {
            product_id: _productId
        };

        $('.woocommerce-mini-cart-wrapper, .page-cart-content').addClass('updating-cart');

        $.ajax({
            type: 'POST',
            url: wc_add_to_cart_params.wc_ajax_url.toString().replace( '%%endpoint%%', 'add_to_cart' ),
            data: data,
            success: function(response) {
                // Trigger event so themes can refresh other areas
                $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash]);
                updateProductLink(_productId);
                $('.woocommerce-mini-cart-wrapper, .page-cart-content').removeClass('updating-cart');
            }
        });
    }

    const updateProductLink = (_productId) => {
        const data = {
            product_id: _productId,
            action: 'woocommerce_update_related_products'
        }

        $.ajax({
            type: 'POST',
            url: wc_add_to_cart_params.ajax_url,
            data: data,
            beforeSend: function( xhr ) {
                $('div.widget_shopping_cart_cross-sell').empty();
            },
            success: function(response) {
                if (response && response.data.fragments) {
                    const fragments = response.data.fragments;
                    console.log(response);
                    $.each(fragments, function(key, value) {
                        $(key).replaceWith(value);
                    });
                }
            }
        });
    }
    /**
     * Remove item from cart
     * @param product_id
     * @param cart_item_key
     */
    const removeFromCart = (product_id, cart_item_key) => {
        const isPageCart = $('.page-cart-content').length > 0;

        const data = {
            action: "woocommerce_remove_from_cart",
            cart_item_key: cart_item_key,
            product_id: product_id,
            page_cart: isPageCart
        };
        // Add class to cart to indicate loading
        $('.woocommerce-mini-cart-wrapper, .page-cart-content').addClass('updating-cart');

        console.log(data);

        $.ajax({
            type: 'POST',
            url: wc_add_to_cart_params.ajax_url,
            data: data,
            success: function(response) {
                // Trigger event so themes can refresh other areas
                // console.log(response);

                if (!isPageCart) {
                    $(document.body).trigger('wc_fragment_refresh');
                    $('.woocommerce-mini-cart-wrapper').removeClass('updating-cart');
                } else {
                    if (response && response.fragments) {
                        console.log(response.fragments);
                        const fragments = response.fragments;
                        $.each(fragments, function (key, value) {
                            $(key).html(value);
                        });
                    }
                    $('.page-cart-content').removeClass('updating-cart');
                }

            }
        });
    }

    const $minicart = $('.woocommerce-mini-cart-wrapper');
    const $pagecart = $('.page-cart-content');
    const $cartWrapper = $('.woocommerce-mini-cart-wrapper, .page-cart-content');
    const $btnCart = $('.btn-show-cart');


    /**
     * add or remove quantity
     */
    $cartWrapper.on('click', '.increment, .decrement', function() {
        console.log('clicked');
        const $input = $(this).siblings('input[type="number"]');
        const currentVal = parseInt($input.val(), 10);
        const max = $input.attr('max') ? parseInt($input.attr('max'), 10) : null;
        const min = $input.attr('min') ? parseInt($input.attr('min'), 10) : 1;

        if ($(this).hasClass('increment')) {
            if (max === null || max === -1 || currentVal < max) {
                $input.val(currentVal + 1).trigger('change');
            }
        } else if ($(this).hasClass('decrement')) {
            if (currentVal > min) {
                $input.val(currentVal - 1).trigger('change');
            } else {
                $input.addClass('disabled')
            }
        }
    });

    /**
     * Update cart item quantity on change
     */
    $cartWrapper.on('change', '.qty.text', (e) => {
        const cart_item_key = $(e.currentTarget).attr('name').match(/\[(.*?)\]/)[1];
        const new_qty = $(e.currentTarget).val();
        updateCart(cart_item_key, new_qty);
    });

    /**
     * Add Item to cart
     */
    $('.btn-add-to-cart').each((index, el) => {
        $(el).on('click', (e) => {
            e.preventDefault();
            addToCart($(el).data('product-id'));
            $('body, html').addClass('mini-cart-open');
        });
    });

    $minicart.on('click', '.woocommerce-cross-sells-list .add-to-cart',(e) => {
        e.preventDefault();
        let el = e.currentTarget;
        addToCart($(el).data('product-id'));
    });

    /**
     * Remove item from cart
     */

    $minicart.on('click', '.mini_cart_item .remove',(e) => {
        e.preventDefault();
        let el = e.currentTarget;
        removeFromCart($(el).data('product_id'), $(el).data('cart_item_key'));
    });

    $pagecart.on('click', '.cart-product-item .remove',(e) => {
        e.preventDefault();
        let el = e.currentTarget;
        removeFromCart($(el).data('product_id'), $(el).data('cart_item_key'));
    });

    /**
     * Show mini cart
     */

    $btnCart.on('click', (e) => {
        if ($('.woocommerce-mini-cart-wrapper').length > 0) {
            e.preventDefault();
            $('body, html').addClass('mini-cart-open');
        }

    });

    /**
     * Close mini cart
     */

    $minicart.on('click', '.btn-close', (e) => {
        e.preventDefault();
        $('body, html').removeClass('mini-cart-open');
    });

    $('.mini-cart-overlay').on('click', (e) => {
        e.preventDefault();
        $('body, html').removeClass('mini-cart-open');
    });
}
