import { ThematicHelpers } from '../helpers.js'

const thematicHelpersInst = new ThematicHelpers();

// Types
/*
const thematicObj = {
    d: 23,
    label: 'Label',
    slug: 'label',
    level: 1,
    parent: 45
}
const routeState = {
    query: indexUiState.query || '',
    page: '1',
    thematic: [typeof thematicObj],
    domains: [typeof thematicObj],
    type: [string],
    start: [string],
    duration: [string],
    place: [string],
    modalite: [string],
    qualification: [string],
}
*/

// Routestate -> URL
const getCreateURL = (indexName, basePath) => {
    return ({ qsModule, routeState, location }) => {
        const { origin } = location;
        const queryParameters = { };
        const baseUrl = `${origin}/${basePath}`;
        const thematicsPath = [];
        let thematicsPathStr = '';
        let queryString = '';
        let pathname = '';


        // Thematics
        if (routeState.thematic.length) {
            routeState.thematic[0] && thematicsPath.push(routeState.thematic[0].slug);
        }

        // Domains
        if (routeState.domains.length) {
            for (const thematic of routeState.domains) {
                thematicsPath.push(thematic.slug);
            }
        }

        if (thematicsPath) {
            thematicsPathStr = `/${thematicsPath.join('/')}`;
        }

        pathname = `${baseUrl}${thematicsPathStr}`;

        // Add other refinements
        /*
        const routeState = {
            query: indexUiState.query || '',
            page: indexUiState.page || 1,
            thematic: [],
            domains: [],
            type: [],
            start: [],
            duration: [],
            place: [],
            modalite: [],
            qualification: [],
        };
        */
        // Cleaning & delete thematic, domains
        Object.keys(routeState).forEach(key => {
            if (key !== 'domains' && key !== 'thematic') {
                const value = routeState[key];
                const hasValue = Array.isArray(value) ? !!value.length : !!value;

                if (hasValue) {
                    if (key === 'page') {
                        try {
                            const num = parseInt(value, 10);

                            num > 1 && (queryParameters['pagenum'] = num);
                        } catch (e) {
                            console.log('Erro while parsing page number', e);
                        }

                    } else {
                        queryParameters[key] = routeState[key];
                    }
                }
            }
        });


        if (Object.keys(queryParameters).length) {
            queryString = `${qsModule.stringify(queryParameters, {
                addQueryPrefix: true,
                arrayFormat: 'comma',
                encode: false
            })}` ;

            !pathname.endsWith('/') && (queryString = `/${queryString}`);
        }
        const resultUrl = `${pathname}${queryString}`;

        // console.log('----- createUrl : pathname: ', pathname , ' resultUrl -----', resultUrl);
        return resultUrl;
    }
}

const getParsedParamsValue = (value) => {
    if (value) {
        return [value];
    }

    return [];
}

// URL -> Routestate
const getParseURL = (indexName, basePath) => {
    return ({ qsModule, location }) => {
        const { pathname, search } = location;
        const queryParameters = qsModule.parse(search.slice(1), {
            comma: true
        });
        const {
            query = '',
            pagenum = '1',
            type = [],
            start = [],
            duration = [],
            place = [],
            modalite = [],
            qualification = []
        } = queryParameters;

        const allTypes = Array.isArray(type) ? type.filter(Boolean) : getParsedParamsValue(type);
        const allStarts = Array.isArray(start) ? start.filter(Boolean) : getParsedParamsValue(start);
        const allDurations = Array.isArray(duration) ? duration.filter(Boolean) : getParsedParamsValue(duration);
        const allPlaces = Array.isArray(place) ? place.filter(Boolean) : getParsedParamsValue(place);
        const allModalites = Array.isArray(modalite) ? modalite.filter(Boolean) : getParsedParamsValue(modalite);
        const allQualifications = Array.isArray(qualification) ? qualification.filter(Boolean) : getParsedParamsValue(qualification);

        const routeState = {
            query: decodeURIComponent(query) || '',
            page: `${pagenum}`,
            thematic: [],
            domains: [],
            type: allTypes.map(item => decodeURIComponent(item)),
            start: allStarts.map(item => decodeURIComponent(item)),
            duration: allDurations.map(item => decodeURIComponent(item)),
            place: allPlaces.map(item => decodeURIComponent(item)),
            modalite: allModalites.map(item => decodeURIComponent(item)),
            qualification: allQualifications.map(item => decodeURIComponent(item)),
        };
        // console.log('getParseURL: queryParameters: ', queryParameters, ', routeState: ', routeState);

        // Build thematics part
        const thematics = thematicHelpersInst.getThematics(pathname);

        if (Array.isArray(thematics) && thematics.length) {
            const mainThematic = thematics.find(item => item.level === 0);
            const domain = thematics.find(item => item.level === 1);
            const subdomain = thematics.find(item => item.level === 2);

            mainThematic && (routeState.thematic = [mainThematic]);

            if (domain) {
                routeState.domains = [domain];

                subdomain && routeState.domains.push(subdomain);
            }
        }

        return routeState;
    }
};

// UIState -> Routestate
const getStateToRoute = (indexName) => {
    return (uiState) => {
        const indexUiState = uiState[indexName] || {};
        const routeState = {
            query: indexUiState.query || '',
            page: indexUiState.page || '1',
            thematic: [],
            domains: [],
            type: [],
            start: [],
            duration: [],
            place: [],
            modalite: [],
            qualification: [],
        };
        const refinementList = indexUiState.refinementList;
        const hierarchicalMenu = indexUiState.hierarchicalMenu;
        // console.log('getStateToRoute > indexUiState --> ', indexUiState);

        if (refinementList) {
            // Thematic
            if (Array.isArray(refinementList.thematic) && refinementList.thematic.length) {
                const thematicObj = thematicHelpersInst.getThematicByLabel(refinementList.thematic[0], 0);

                if (thematicObj) {
                    routeState.thematic = [thematicObj];
                }
            }

            // Type
            if (Array.isArray(refinementList['typeProduct.label'])) {
                routeState.type = refinementList['typeProduct.label'];
            }

            // Month start
            if (Array.isArray(refinementList['monthStartTs'])) {
                routeState.start = refinementList['monthStartTs'];
            }

            // Duration
            if (Array.isArray(refinementList.durationRange)) {
                routeState.duration = refinementList.durationRange;
            }

            // Place
            if (Array.isArray(refinementList['place'])) {
                routeState.place = refinementList['place'];
            }

            // Modalities
            if (Array.isArray(refinementList['modalite.label'])) {
                routeState.modalite = refinementList['modalite.label'];
            }

            // Qualification
            if (Array.isArray(refinementList.qualificationLabel)) {
                routeState.qualification = refinementList.qualificationLabel
            }
        }

        // Domains
        if (hierarchicalMenu) {
            if (Array.isArray(hierarchicalMenu['domains.lvl0'])) {
                routeState.domains = hierarchicalMenu['domains.lvl0'].map((item, index) => {
                   return thematicHelpersInst.getThematicByLabel(item, index + 1);
                }).filter(Boolean);
            }
        }

        // console.log('----- stateToRoute : uiState -----', uiState, ' routeState -----', routeState);

        return routeState;
    };
}

// Routestate -> UIState
const getRouteToState = (indexName) => {
    return (routeState) => {
        const uiState = {
            [indexName] : {
                configure: { hitsPerPage: 10 },
                query: routeState.query || '',
                page: routeState.page || '1',
                refinementList: {
                    thematic: [],
                    durationRange: [],
                    'modalite.label': [],
                    monthStartTs: [],
                    'typeProduct.label': [],
                    place: [],
                    qualificationLabel: []
                },
                hierarchicalMenu: {
                    'domains.lvl0': null
                }
            }
        };
        const shState = uiState[indexName]; // shorthand
        const refinementList = shState.refinementList;


        // Thematic
        if (routeState.thematic.length) {
            refinementList.thematic = [routeState.thematic[0].label];
        }

        // Domains
        if (routeState.domains.length) {
            shState.hierarchicalMenu['domains.lvl0'] = routeState.domains.map(item => item.label);
        }

        // Type
        if (Array.isArray(routeState.type)) {
            refinementList['typeProduct.label'] = routeState.type;
        }

        // Month start
        if (Array.isArray(routeState.start)) {
            refinementList['monthStartTs'] = routeState.start;
        }

        // Duration
        if (Array.isArray(routeState.duration)) {
            refinementList.durationRange = routeState.duration;
        }

        // Place
        if (Array.isArray(routeState.place)) {
            refinementList['place'] = routeState.place;
        }

        // Modalities
        if (Array.isArray(routeState.modalite)) {
            refinementList['modalite.label'] = routeState.modalite;
        }

        // Qualification
        if (Array.isArray(routeState.qualification)) {
            refinementList.qualificationLabel = routeState.qualification;
        }

        // console.log('----- routeToState : routeState -> ', routeState , ' uiState ----->', uiState);
        return uiState;
    };
}

const getRouter = (indexName, basePath) => {
    return window.instantsearch.routers.history({
        createURL: getCreateURL(indexName, basePath),
        parseURL: getParseURL(indexName, basePath)
    })
};


const getStateMapping = (indexName) => {
    return {
        stateToRoute: getStateToRoute(indexName),
        routeToState: getRouteToState(indexName)
    }
}

export function getRouting(indexName, allThematics = [], basePath) {
    thematicHelpersInst.init(allThematics);

    return {
        router: getRouter(indexName, basePath),
        stateMapping: getStateMapping(indexName),
    };
}
