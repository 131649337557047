import {
    getSearchWidget,
    getCoursesResult,
    getConfigureWidget,
    getStatsWidget,
    getSuggestions,
    QueryDispatcher
} from './widgets'

export default class MainSearch extends HTMLElement {
    constructor() {
        super();

        this.searchClient = null;
        this.search = null;
        this.mainBody = null;
        this.hasQuery = false;
        this.hitsPerPage = 20;
        this.algoliaIndex = null;
        this.qDispatcher = new QueryDispatcher();
        this.searchForm = null;
        this.inputSearch = null;
        this.firstRun = false;
    }

    /*
    * Output the stats phrase in top of the results
    * Ex: 12 résultats pour "con"
    * */
    statsLabelGenerator(htmlFunc, data, maxHits) {
        const qty = data.nbHits > maxHits ? '+20' : data.nbHits;
        const result = `résultat${data.nbHits > 1 ? 's' : ''}`;

        if (!data.query) {
            return null;
        }

        return htmlFunc`${qty} ${result} pour “${data.query}”`;
    }

    /*
    * Add widgets for instantsearch instance
    * */
    buildWidgets (searchInst) {
        const searchBoxEl = this.querySelector('[data-widget="searchbox"]');
        const hitsContainer = this.querySelector('.algolia-result > div[data-widget="hits"]');
        const statsEl = this.querySelector('[data-widget="stats"]');
        const widgets = [getConfigureWidget(this.hitsPerPage)];

        searchBoxEl && widgets.push(getSearchWidget(searchBoxEl));
        hitsContainer && widgets.push(getCoursesResult(hitsContainer));
        statsEl && widgets.push(getStatsWidget(statsEl, (htmlFunc, data) => {
            return this.statsLabelGenerator(htmlFunc, data, this.hitsPerPage);
        }));
        widgets.length && searchInst.addWidgets(widgets);
    }

    /*
    * Init query suggestions features
    * */
    initQuerySuggestions(searchClient, indexName) {
        const base = this;
        const suggestions = window.instantsearch({
            indexName: `${indexName}_query_suggestions`,
            searchClient,
            insights: true,
            searchFunction(helper) {
                if (base.firstRun) {
                    helper.search();
                }
            }
        });
        const autoComplete = getSuggestions();
        const container = document.querySelector('#autocomplete-container .keywords-wrapper');

        if (container) {
            suggestions.addWidgets([
                window.instantsearch.widgets.configure({
                    hitsPerPage: 10,
                }),
                autoComplete({
                    container,
                    queryListener: this.qDispatcher,
                    onSelectChange({ query }) {
                        // Url to formation base url with query
                        base.doSearch(query);
                    }
                })
            ]);

            suggestions.start();
        }
    }

    /*
    * Initializa the instantsearch instance
    * */
    buildInstantSearch(appId, appKey, indexName, hasMostWanted) {
        const base = this;

        try {
            this.searchClient = window.algoliasearch(appId, appKey);
            this.search = window.instantsearch({
                searchClient: this.searchClient,
                indexName,
                insights: true,
                searchFunction(helper) {
                    // const hasQuery = helper.state.query && helper.state.query.length > 2;

                    if (helper.state.query || (!hasMostWanted && !helper.state.query)) {
                        helper.search();
                    }
                }
            });

            const syncMiddleware = () => {
                return {
                    onStateChange({ uiState }) {
                        if (uiState[indexName]) {
                            base.setQueryClass(!!uiState[indexName].query);
                            base.qDispatcher.doChange(uiState[indexName].query || '');
                        }
                    }
                };
            };

            this.search.use(syncMiddleware);
            this.buildWidgets(this.search);

            return true;
        } catch (e) {
            console.warn('Error while initializing main instantsearch', e);
        }

        return false;
    }

    /*
    * Match this layer search box size with the header input search's size
    * */
    setSearchWidth() {
        const isMobile = window.innerWidth < 1024;
        const headerSearchElSelector = `#pf-header form.banner-top-search${isMobile ? '.mobile' : ''}`
        const headerSearchEl = document.querySelector(headerSearchElSelector);
        const headerLogoEl = document.querySelector('#pf-header .banner-top-logo');
        const headerSearchBounds = headerSearchEl && headerSearchEl.getBoundingClientRect();
        const headerLogoElBounds = headerLogoEl && headerLogoEl.getBoundingClientRect();

        headerSearchBounds && this.style.setProperty('--header-search-width', `${headerSearchBounds.width}px`);
        headerLogoElBounds && this.style.setProperty('--header-logo-width', `${headerLogoElBounds.width}px`);
    }

    /*
    * Window resize handler
    * */
    onWindowResize() {
        setTimeout(() => {
            this.setSearchWidth();
        }, 250);
    }

    /*
    * Handle open/close of this layer
    * */
    openSearchLayer() {
        this.classList.toggle('show', true);
        this.mainBody.classList.toggle('open', true);
        document.querySelector('html').classList.toggle('open', true);

        this.inputSearch && this.inputSearch.focus();
        document.addEventListener('keydown', this.handleEscape.bind(this));

        if (!this.firstRun) {
            this.initQuerySuggestions(this.searchClient, this.algoliaIndex);
            this.search.start();
        }
    }

    closeSearchLayer() {
        this.classList.toggle('show', false);
        const bannerPrincipalMobileEl = document.querySelector('header .banner-principal.open')

        if (!bannerPrincipalMobileEl ) {
            this.mainBody.classList.remove('open');
            document.querySelector('html').classList.remove('open');
        }

        this.inputSearch && this.inputSearch.blur();
        document.removeEventListener('keydown', this.handleEscape.bind(this));
    }

    /*
    * Validate search input
    * */
    handleSearchSubmit() {
        const value = `${this.inputSearch.value}`.trim();

        if (value) {
            this.closeSearchLayer();
            location.href = `${location.origin}/formation/?query=${value}`;
        }
    }

    /*
    * Gestion touche escape
    * */
    handleEscape(evt) {
        if (evt.key === 'Escape') {
            this.closeSearchLayer();
        }
    }

    /*
    *
    * Set a class name if a query is on
    * so we can hide predefined results on wp_admin
    * */
    setQueryClass(hasQuery) {
        const resultBodyEl = this.querySelector('.search-body');

        resultBodyEl && resultBodyEl.classList.toggle('has-query', hasQuery);
    }

    /*
    * Add click listener for each keyword
    * in popular search words
    * */
    setSearchByKeywords() {
        const keywords = this.querySelectorAll('.keywords-wrapper > button.keyword');

        keywords.forEach(button => {
            button.addEventListener('click', () => {
               this.doSearch(button.innerText);
            });
        })
    }
$
    /*
    * Called after click on keyword popular
    * or suggestion
    * */
    doSearch(query) {
        if (typeof query === 'string') {
            window.location.href = `${window.location.origin}/formation/?query=${encodeURIComponent(query)}`;
        }
    }

    onSearchRender() {
        if (!this.firstRun) {
            this.firstRun = true;
            this.searchForm = this.querySelector('form.ais-SearchBox-form');
            this.inputSearch = this.searchForm.querySelector('input.ais-SearchBox-input');

            this.searchForm && this.searchForm.addEventListener('submit', this.handleSearchSubmit.bind(this));
        }
    }

    /**
     * Initialisation
     */
    init() {
        const {
            appId,
            appKey,
            indexName,
            hasMostWantedTrainingCourses
        } = this.dataset;
        const btnCloseEl = this.querySelector('button.btn-close');
        const searchBodyEl = this.querySelector('.search-body');
        const hasMostWanted = hasMostWantedTrainingCourses ? !!parseInt(hasMostWantedTrainingCourses, 10) : false;

        this.algoliaIndex = indexName;
        this.mainBody = document.querySelector('body');
        if (appId && appKey && indexName) {
            if (this.buildInstantSearch(appId, appKey, indexName, hasMostWanted)) {

                btnCloseEl && btnCloseEl.addEventListener('click', this.closeSearchLayer.bind(this));

                this.search.on('render', this.onSearchRender.bind(this));
                window.addEventListener('resize', this.onWindowResize.bind(this));
                this.onWindowResize();
                this.setSearchByKeywords();
            }
        }
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        })
    }
}
