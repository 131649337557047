export default class ShowHideContact {

    constructor() {
        this.pushcontacts = document.querySelectorAll('.show-hide-contact');
        this.init();
    }

    init() {
        console.log('show hide contact init');
        console.log('pushcontacts', this.pushcontacts);

        if (this.pushcontacts.length) {
            this.pushcontacts.forEach(pushcontact => {
                const pushcontactwrapper = pushcontact.querySelector('.section-push-contact-wrapper');
                const bntmail = pushcontact.querySelector('.btn-email');
                const email = pushcontact.querySelector('.email');

                bntmail.addEventListener('click', (e) => {
                    e.preventDefault();
                    email.classList.toggle('hidden');
                    bntmail.querySelector('.label').innerHTML =
                    email.classList.contains('hidden') ? 'Afficher le contact' : 'Masquer le contact';
                    pushcontactwrapper.classList.toggle('bg-image-position')

                })
            })
        }
    }

}
