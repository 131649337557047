import stringSimilarity from "string-similarity-js";

export default class TmlPwdValidation {
    constructor() {
        this.$passwdInput = $('input#pass1');
        this.$passwdInputConfirm = $('input#pass2');
        this.$submit = $('.tml-submit-wrap [type=submit]');
        this.$acceptTerms = $('#accept_terms');

        this.$passwdInput.on('keyup change', () => {
            this.checkSimilarity();
            this.checkStrongPwd();
            this.validatePwdConfirmation();
        });

        this.$passwdInputConfirm.on('keyup change blur', () => {
            this.validatePwdConfirmation();
        });

        if (this.$acceptTerms.length > 0) {
            this.$acceptTerms.on('change', () => {
                this.readyToSubmit();
            });
        }
    }

    checkSimilarity() {
        (stringSimilarity($('#first_name').val() + ' ' + $('#last_name').val(), this.$passwdInput.val()) < 0.1 && $('input#pass1').val().length > 3) ? $('li.similar').removeClass('invalid').addClass('checked') : $('li.similar').addClass('invalid').removeClass('checked');
    }

    checkStrongPwd() {
        let validationRegex = [
            { class: 'count',   regex: /.{8,}/ }, // min 8 letters,
            { class: 'number',  regex: /[0-9]/ }, // numbers from 0 - 9
            { class: 'lower',   regex: /[a-z]/ }, // letters from a - z (lowercase)
            { class: 'upper',   regex: /[A-Z]/ }, // letters from A-Z (uppercase),
            { class: 'special', regex: /[^A-Za-z0-9]/ } // special characters
        ];

        $.each(validationRegex, (index, item) => {
            let isValid = item.regex.test(this.$passwdInput.val());

            if (isValid) {
                $('li.' + item.class).removeClass('invalid').addClass('checked');
            } else {
                $('li.' + item.class).addClass('invalid').removeClass('checked');
            }
        })
    }

    validatePwdConfirmation () {
        if (stringSimilarity(this.$passwdInput.val(), this.$passwdInputConfirm.val()) == 1) {
            $('li.equal').removeClass('invalid').addClass('checked');
            $('.tml-user_pass2-wrap').removeClass('invalid').addClass('checked');
        } else {
            $('li.equal').addClass('invalid').removeClass('checked');
            $('.tml-user_pass2-wrap').addClass('invalid').removeClass('checked');
        }

        this.readyToSubmit();
    }

    readyToSubmit () {
        const _ready = this.$acceptTerms.length > 0 ? $('ul.checklist li').length == $('ul.checklist li.checked').length && this.$acceptTerms[0].checked : $('ul.checklist li').length == $('ul.checklist li.checked').length;

        if (_ready) {
            this.$submit.prop("disabled", false);
        } else {
            this.$submit.prop("disabled", true);
        }
    }
}
