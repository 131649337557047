export default class StickyBlock {
    static instances = []; // Tableau pour stocker les instances

    constructor() {
        this.stickyBlock();
    }

    stickyBlock() {
        // Vérifie si Sticksy est déjà initialisé
        if (document.querySelectorAll('.sticksy-dummy-node').length > 0) {
            return false;
        }

        console.log('sticky-block : init');
        const stickyEls = document.querySelectorAll('.block--sticksyJs'); // Sélectionne tous les éléments avec la classe
        if (stickyEls && stickyEls.length > 0) {
            stickyEls.forEach((stickyEl) => {
                let stickyInstance = new Sticksy(stickyEl, {
                    topSpacing: 165,
                });

                StickyBlock.instances.push(stickyInstance); // Ajoute l'instance dans le tableau
            });
        }

        const resizeObserver = new ResizeObserver((entries) => {
            Sticksy.hardRefreshAll();
            console.log('StickyBlock.instances', StickyBlock.instances);
        });


        if (document.querySelector('.single-product-wrapper')) {
            this.updateSticky(resizeObserver);

            jQuery(window).on('resize', () => {
                this.updateSticky(resizeObserver);
            });
        }
    }

    updateSticky(resizeObserver) {
        const productWrapper = document.querySelector('.single-product-wrapper');
        const productAside = document.querySelector('.single-product-aside');

        if (!productWrapper || !productAside) return; // Vérifie que les éléments existent

        if (jQuery(window).width() < 1024) {
            resizeObserver.unobserve(productWrapper);

            // Désactiver toutes les instances Sticksy proprement
            StickyBlock.instances.forEach(instance => {
                if (instance.disable) {
                    instance.disable();
                }
            });

            productAside.style.position = "fixed";
            console.log('Sticksy instances disabled');
        } else {
            resizeObserver.observe(productWrapper);

            // Réactiver toutes les instances Sticksy proprement
            StickyBlock.instances.forEach(instance => {
                if (instance.enable) {
                    instance.enable();
                }
            });

            productAside.style.position = "relative";
        }
    }

    static getInstances() {
        return StickyBlock.instances; // Retourne toutes les instances
    }
}
