import queryString from 'query-string';
import { collapseButtonText } from './panel.js';
import { getCleanThematicLabel } from '../helpers.js'

class UiStateWrapper {
    constructor () {
        this.current = null;
    }

    setState (uis) {
        this.current = uis;
    }

    getState () {
        return this.current;
    }
}

const domainsHierarchicalMenu = window.instantsearch.widgets.panel({
    templates: {
        header() {
            return 'Domaines';
        },
        collapseButtonText,
    },
    collapsed: () => false,
})(window.instantsearch.widgets.hierarchicalMenu);
export const searchUiState = new UiStateWrapper();
export const getDomainsFilter = (containerId = '[data-widget="domains"]') => {
    return domainsHierarchicalMenu(
        {
            container: containerId,
            attributes: [
                'domains.lvl0',
                'domains.lvl1'
            ],
            limit: 100,
            transformItems(items, { results }) {
                const filters = results.params && queryString.parse(results.params);
                const parsedFacets = filters && filters.facetFilters && JSON.parse(filters.facetFilters);
                let selectedThematic = null;

                if (parsedFacets) {
                    for (const facet of parsedFacets) {
                        const token = 'thematic:';

                        for (const item of facet) {
                            if (item.startsWith(token) && !selectedThematic) {
                                selectedThematic = item.replace(token, '');
                                break;
                            }
                        }

                        if (selectedThematic) {
                            break;
                        }
                    }
                }

                return items.map(domain => {
                    return {
                        ...domain,
                        label: getCleanThematicLabel(domain.label)
                    };
                }).filter(domain => {
                    if (selectedThematic) {
                        return domain.value.includes(selectedThematic);
                    }

                    return true;
                });
            }
        },
    );
}
