import TmlPwdValidation from "../components/tml-pwd-validation.js";

export default {
    init() {
        console.log('init tml-action-activate.js');
        // JavaScript to be fired on the Signup Activation Page
        const activatePwd = new TmlPwdValidation();

    },
};
