export default function ($) {
    console.log('checkout order review');

    $('#billing_country, #shipping_country, input[name="ship_to_different_address_1"]').on('change', (e)=> {
        $( document.body ).trigger( 'update_checkout');
    })

    $(document.body).on('checkout_error', function(e, errors) {
        let errorMessages = '<div class="gform_validation_errors" tabindex="-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" aria-labelledby="errorIconTitle" color="#000" viewBox="0 0 24 24"><path d="M12 8v5M12 16h0"></path><circle cx="12" cy="12" r="10"></circle></svg>\n' +
            '                                <div class="text">Une erreur s’est produite lors de votre envoi. <br>\n' +
            '                                    Veuillez vérifier les champs ci-dessous.</div>\n' +
            '                            </div>';
        const $errorsList = $(errors);
        const $errorsListItems = $errorsList.find('li[data-id]');

        $errorsListItems.each((i, item) => {
            // console.log('item', item);
            const $item = $(item);
            // console.log('$item', $item);
            const id = $item.data('id');
            const $fieldWrapper = $(`.${id}`);
            const $errorWrapper = $('<div class="gfield_error_message"></div>');
            $errorWrapper.append($item.text());
            $fieldWrapper.addClass('gfield_error');
            $fieldWrapper.append($errorWrapper);
        });

        $(".checkout-infos-order").removeClass('open-order');
        $('.checkout-infos-content').removeClass('infos-is-valid');

        $('.woocommerce-error-checkout').html(errorMessages);
    });
}
