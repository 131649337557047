export default class Tml {
    /**
     * @param tmlBodyClass as selector
     */
    constructor(tmlBodyClass) {
        this.$form = $(tmlBodyClass);
        this.inputs = $('input', this.$form).not('[type=submit], [type=hidden]');
        this.$submit = $('.tml-submit-wrap [type=submit]', this.$form);

        this.inputs.on('keyup change blur', ()=>{
            this.validateForm();
        });
    }

    validateForm () {
        let isValid = true;
        this.inputs.each((index, elem)=>{
            const inputType = elem.type;

            switch (inputType) {
                case 'text':
                case 'password':
                    isValid = elem.value.trim() != '';
                    break;
                case 'email':
                    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    isValid = regex.test(elem.value);
                    break;
            }

            if(!isValid) {
                return false;
            }
        })

        if (isValid) {
            this.$submit.prop("disabled", false);
        } else {
            this.$submit.prop("disabled", true);
        }
    }

    init () {
        console.log('tml.init')
    }
}
