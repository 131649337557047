export default class VideosLauncher extends HTMLElement {
    constructor() {
        super();
        this.iframeHtml = null;
    }

    /**
     * Initialisation
     */
    init() {
        const { iframe  } = this.dataset;
        if (iframe) {
            this.iframeHtml = iframe.replace('data-src', 'src');
            this.addEventListener('click', this.onPlay.bind(this));
        }
    }

    onPlay(evt) {
        evt && evt.preventDefault();
        $('.popin-video .oembed-responsive').append(this.iframeHtml);
        this.showVideo();

        $('.popin-video .btn-close').on('click', (e) => {
            this.hideVideo();
            return false;
        });
    }

    connectedCallback() {
        setTimeout(() => {
            this.init()
        })
    }

    showVideo () {
        $('html').addClass('popin-video-open');
    }

    hideVideo () {
        $('.popin-video .oembed-responsive iframe').remove();
        $('html').removeClass('popin-video-open');
    }
}
