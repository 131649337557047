export default {
    init () {
        console.log ('init tml-action');
        this.showPwd = $('.tml-field-type__password .show-password');

        this.showPwd.each((index, elem) => {
            $(elem).on('click', (e)=> {
                $(elem).toggleClass('eye-close');
                let $input = $(elem).siblings('input');
                $input.get(0).type = $input.get(0).type == 'password' ? "text" : "password";
            });
        });
    }
}
