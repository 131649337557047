/**
 * Doc : https://tarekraafat.github.io/autoComplete.js/
 * GF_PREINSCRIPTION & GF_PARTICIPANT are define in footer / Values are define in ENV
 */
import autoComplete from "@tarekraafat/autocomplete.js";
export default {
    init() {
        console.log('init page-preinscription', gform);
        this.fakedata = [
            {
                "siret": "2699840895906940",
                "company": "Cole Inc",
                "phone": "+33123456789",
                "address": "5414 Kub Mills",
                "postcode": "750015",
                "city": "Paris",
                "country": "France"
            },
            {
                "siret": "5228704159827114",
                "company": "Keeling Group",
                "phone": "+19844989273",
                "address": "40606 Wiza Club Apt. 342",
                "postcode": "69930-2027",
                "city": "Bashirianfort",
                "country": "Géorgie du Sud et Îles Sandwich du Sud"
            },
            {
                "siret": "343067502659142",
                "company": "Mills, Tromp and Koss",
                "phone": "+13363629755",
                "address": "270 Armstrong Drive Apt. 628",
                "postcode": "71499-7046",
                "city": "Lysanneport",
                "country": "Costa Rica"
            },
            {
                "siret": "5141825128325387",
                "company": "Ondricka Group",
                "phone": "+16367455111",
                "address": "3542 Rutherford Oval Apt. 222",
                "postcode": "71821-9967",
                "city": "Port Deontebury",
                "country": "Guinée"
            }
        ];

        this.fakeUserData = [
            {
                "uuid": "721a667a-c100-3fc6-b60d-8c19b2de94b0",
                "email": "edias@gmail.com",
                "civility": "Madame",
                "firstname": "Victor",
                "lastname": "Rocher",
                "phone": "+33038541563",
                "address": "49, boulevard de Collin",
                "postcode": "50184",
                "city": "Simon",
                "country": "Éthiopie",
                "function": "distinctio",
                "service": "aspernatur"
            },
            {
                "uuid": "e13ef4ab-77a2-3876-871e-f9c3ea254607",
                "email": "courtois.camille@chauveau.org",
                "civility": "Mademoiselle",
                "firstname": "Juliette",
                "lastname": "Colas",
                "phone": "+33873616871",
                "address": "impasse Grégoire Munoz",
                "postcode": "44710",
                "city": "Lefevre-sur-Lejeune",
                "country": "Hongrie",
                "function": "et",
                "service": "voluptas"
            },
            {
                "uuid": "99f2c756-ee6e-3375-9c49-b71a6d431b7b",
                "email": "marie.lorraine@club-internet.fr",
                "civility": "Monsieur",
                "firstname": "Jean",
                "lastname": "Rodriguez",
                "phone": "+33942381379",
                "address": "40, impasse de Leduc",
                "postcode": "21800",
                "city": "Georges",
                "country": "La Barbad",
                "function": "suscipit",
                "service": "commodi"
            },
            {
                "uuid": "45654a70-f75c-34db-a37d-85abd1b42ba1",
                "email": "roland64@evrard.net",
                "civility": "Monsieur",
                "firstname": "Alix",
                "lastname": "Benoit",
                "phone": "+33914748139",
                "address": "16, chemin Gonzalez",
                "postcode": "57444",
                "city": "Dufour",
                "country": "Mali",
                "function": "inventore",
                "service": "dolores"
            },
            {
                "uuid": "b6b16081-0440-3c17-a41e-9c2b858f924f",
                "email": "julien06@munoz.fr",
                "civility": "Monsieur",
                "firstname": "Pierre",
                "lastname": "Marty",
                "phone": "+33213557994",
                "address": "boulevard de Bourgeois",
                "postcode": "42997",
                "city": "De Oliveiraboeuf",
                "country": "Angola",
                "function": "eum",
                "service": "vel"
            },
            {
                "uuid": "5d2e3e4d-cb89-398a-a76b-9f64da4b410a",
                "email": "denis.pichon@wanadoo.fr",
                "civility": "Monsieur",
                "firstname": "Marc",
                "lastname": "Martel",
                "phone": "+33201760979",
                "address": "4, place Delmas",
                "postcode": "33673",
                "city": "Parentboeuf",
                "country": "Paraguay",
                "function": "possimus",
                "service": "sunt"
            },
            {
                "uuid": "1373bbef-5d05-30f8-9bb4-4135b5a27007",
                "email": "eugene.lemaire@club-internet.fr",
                "civility": "Madame",
                "firstname": "Astrid",
                "lastname": "Torres",
                "phone": "+33543891899",
                "address": "4, rue Alfred Hebert",
                "postcode": "22659",
                "city": "PasquierVille",
                "country": "Saint-Marin (Rép. de)",
                "function": "id",
                "service": "et"
            }
        ]

        // Fields matching
        this.fields = {
            "civility" : {
                "type": "select",
                "field": ["4"]
            },
            "firstname" : {
                "type": "text",
                "field": ["5"]
            },
            "lastname" : {
                "type": "text",
                "field": ["6"]
            },
            "company" : {
                "type": "text",
                "field": ["27"]
            },
            "phone" : {
                "type": "phone",
                "field": ["29", "7"]
            },
            "tva" : {
                "type": "text",
                "field": ["38"]
            },
            "function" : {
                "type": "text",
                "field": ["27", "9"]
            },
            "service" : {
                "type": "text",
                "field": ["39","10"]
            },
            "billing" : {
                "type": "text",
                "field": ["40"]
            },
            "address" : {
                "type": "text",
                "field": ["30","11"]
            },
            "complement" : {
                "type": "text",
                "field": ["31"]
            },
            "postcode" : {
                "type": "text",
                "field": ["32","12"]
            },
            "city" : {
                "type": "text",
                "field": ["33","13"]
            },
            "country" : {
                "type": "select",
                "field": ["34","15"]
            },
        }
        this.fieldsEntreprise = [25, 26, 27, 29, 30, 31, 32, 33, 34];
        this.fieldIsFinanced = $('.gfield--type-radio input[type=radio][name=input_24]');

        this.$profil = $('.gfield-profil-user input[type=radio][name=input_1]');
        this.$searchSiret = jQuery('.ginput_container_text_siret input[type=text]');
        console.log(this.$searchSiret);

        if (this.$searchSiret.length > 0) {
            this.getCompaniesData();

            if (this.$searchSiret.val().trim() != '') {
                this.showSiretHiddenFields();
            }
        }

        this.fieldIsFinanced.on('change', () => {
            let isfinanced = this.fieldIsFinanced.filter(':checked').val();
            // console.log(isfinanced);
            this.fieldsEntreprise.forEach((fieldId) => {
                console.log($('#field_' + GF_PREINSCRIPTION + '_' + fieldId));
                if (isfinanced == 0) {
                    $('#field_' + GF_PREINSCRIPTION + '_' + fieldId).hide();
                } else {
                    $('#field_' + GF_PREINSCRIPTION + '_' + fieldId).show();
                }
            });
        })

        this.$profil.on('change', () => {
            this.changeButtonNextLabel();
        });
        if (this.$profil.length > 0){
            $('#input_' + GF_PREINSCRIPTION +'_47_1').on('change', (e) => {
                if ( e.currentTarget.checked ) {
                    $('.gform_submit_button').prop('disabled', false);
                } else {
                    $('.gform_submit_button').prop('disabled', true);
                }
            });

            $('#input_' + GF_PREINSCRIPTION +'_46_1').on('change', (e) => {
                if ( e.currentTarget.checked ) {
                    $('.gform_next_button').prop('disabled', false);
                } else {
                    $('.gform_next_button').prop('disabled', true);
                }
            });

            this.changeButtonNextLabel();
        }

        gform.addFilter( 'gpnf_fetch_form_html_after_add', () => {
            this.updateEntryOrder();
            this.updateNumeroEntries();
        });

        gform.addFilter( 'gpnf_fetch_form_html_after_delete', () => {
            this.updateEntryOrder();
        });

        gform.addFilter('gpnf_modal_args', (args) => {
            args.cssClass = 'modal-preinscription';
            console.log(args);
            return args;
        });

        gform.addFilter('gpnf_modal_button_css_classes', (btnClasses, btnType) => {
            console.log(btnClasses, btnType);
            btnClasses += ' btn btn-dark';
            return btnClasses;
        });

        gform.addAction( 'gpnf_init_nested_form', () => {
            setTimeout(()=>{
                this.getUsersData()
            }, 200);
        });

        $('.btn-aside-open').on('click', () => {
            $('body, html').toggleClass('aside-open');
            return false;
        })
    },

    updateNumeroEntries () {
        const $participantList = $('.gpnf-nested-entries-list > li');
        let $numSpan = $('<span class="num"></span>');

        $participantList.each((index, elem) => {
            $(elem).find('.num').remove();
            $numSpan.text('Participant ' + (index + 1) + ' : ');
            $(elem).prepend($numSpan.clone());
        });
    },

    updateEntryOrder () {
        const val = $('.gfield-profil-user input[type=radio][name=input_1]:checked').val();
        const $quantityWrapper = $('[data-quantity]');
        const $totalPriceWrapper = $('[data-total-price]');
        const currentPrice = $('[data-current-price]').data('current-price');
        const $participantList = $('.gpnf-nested-entries-list > li');

        let quantity = $quantityWrapper.data('quantity');
        let totalPrice = 0;
        // console.log($quantityWrapper, quantity, val);

        if ((val === 'participant') && (Number(quantity) == 1)) {
            $quantityWrapper.html('1 participant');
            $quantityWrapper.attr('data-quantity', 1);
            $totalPriceWrapper.text(this.formatPriceCurrency(currentPrice));
            $totalPriceWrapper.attr('data-total-price', currentPrice);
        } else if (val === 'collabo') {
            const participantQty = $participantList.length;
            console.log($participantList, participantQty, val);
            let qtyStr = (participantQty > 1) ? participantQty + ' participants' : (participantQty == 1) ? '1 participant' : 'Aucun participant';

            totalPrice = currentPrice * participantQty;
            $quantityWrapper.html(qtyStr);
            $quantityWrapper.attr('data-quantity', participantQty);
            $totalPriceWrapper.text(this.formatPriceCurrency(totalPrice));
            $totalPriceWrapper.attr('data-total-price', totalPrice);
        }
    },

    changeButtonNextLabel() {
        let $nextBtn = $('.gform_next_button');
        let $submitBtn = $('.gform_submit_button');
        const val = $('.gfield-profil-user input[type=radio][name=input_1]:checked').val();

        if (val === 'participant') {
            $nextBtn.find('.label').text('Valider la pré-inscription');
            if (!$('#input_' + GF_PREINSCRIPTION + '_46_1').get(0).checked) {
                $nextBtn.prop('disabled', true);
            }
        } else if (val === 'collabo') {
            $nextBtn.find('.label').text('Continuer');
            $submitBtn.prop('disabled', true);
            $nextBtn.prop('disabled', false);

            this.fieldsEntreprise.forEach((fieldId) => {
                $('#field_' + GF_PREINSCRIPTION + '_' + fieldId).show();
            });
        }
    },

    // Fonction de recherche par préfixe de SIRET
    async searchCompaniesBySiretPrefix(prefix){
        if (prefix.length < 3) {
            return [];
        }

        try {
            const response = await $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action: 'get_insee_data',
                    query: prefix
                },
                dataType: 'json'
            });

            if (response.success) {
                return response.data;
            } else {
                throw new Error(`Erreur: ${response.data}`);
            }
        } catch (error) {
            console.error("Erreur de recherche par préfixe de SIRET:", error);
            return [];
        }
    },

    async getContactsByPrefixEmail(prefix){
        if (prefix.length < 1) {
            return [];
        }

        try {
            const response = await $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action: 'get_participant_data',
                    query: prefix
                },
                dataType: 'json'
            });

            if (response.success) {
                return response.data;
            } else {
                throw new Error(`Erreur: ${response.data}`);
            }
        } catch (error) {
            console.error("Erreur de recherche contacts par préfixe email :", error);
            return [];
        }
    },

    getCompaniesData () {
        const autoCompleteCompany = new autoComplete({
            selector: '#input_' + GF_PREINSCRIPTION +'_26',
            data: {
                // src: this.fakedata,
                src: async (query) => {
                    //console.log('query=',query);
                    if (query.length == 0) {
                        let userCompany = []
                        if(DYNAMICS_COMPANY){
                            try {
                                const dynamics_company = DYNAMICS_COMPANY;
                                userCompany = [dynamics_company];
                            } catch (error) {
                                userCompany = [];
                                console.error('Erreur = ', error);
                            }
                        }
                        return userCompany;
                    }

                    try {
                        if (query.length >=3 ) {
                            return this.searchCompaniesBySiretPrefix(query);
                        } else {
                            return [];
                        }
                    } catch (error) {
                        return error;
                    }
                },
                keys: ["siret"]
            },

            threshold: 0,

            query: (input) => {
                return input.replace(/\s/g,'');
            },

            resultsList: {
                //tag: 'div',
                element: (list, data) => {
                    const $li = $('<li class="link"></li>');
                    const $link = $('<a href="javascript:;"></a>');
                    $link.html('Je ne connais pas le numéro de SIRET');
                    $li.append($link)
                    $(list).append($li);
                },
                noResults: true,
                maxResults: 10
            },

            resultItem: {
                element: (item, data) => {
                    $(item)
                        .empty()
                        .append('<span class="company">' + data.value.company + '</span>')
                        .append('<span class="address">' + data.value.address + ', ' + data.value.postcode + ' ' + data.value.city + '</span>')
                },
                highlight: true,
            },
            events: {
                input: {
                    init: (event) => {
                        /*let listElement = $(event.target).next('#autoComplete_list_1');
                        const dynamics_company = DYNAMICS_COMPANY ? JSON.parse(DYNAMICS_COMPANY) : [];

                        if(dynamics_company){
                            let dynamicResult = `<li id="autoComplete_result_0" role="option">
                                <span class="company">${dynamics_company['company']}</span>
                                <span class="address">${dynamics_company['address']}</span>
                            </li>`;
                            listElement.append(dynamicResult);
                        }*/
                    },
                    focus: (event) => {
                        autoCompleteCompany.start();
                        autoCompleteCompany.open();
                    }
                },
            },
        });

        autoCompleteCompany.input.addEventListener("selection",  (event) => {
            const feedback = event.detail;
            autoCompleteCompany.input.blur();

            // Prepare User's Selected Value
            if (feedback.selection.value) {
                const selection = feedback.selection.value[feedback.selection.key];
                const values = feedback.selection.value;

                // Replace Input value with the selected value
                autoCompleteCompany.input.value = selection;

                // Fill fields
                this.populateFields(values, GF_PREINSCRIPTION);
                jQuery(autoCompleteCompany.input).mask('999 999 999 99999');
            } else {
                const $containerReadonly = $('#gform_fields_' + GF_PREINSCRIPTION + ' .ginput_container.ginput_container_readonly');

                $containerReadonly.find('input').prop('readonly', false).val('');
                $containerReadonly.removeClass('ginput_container_readonly');
                // console.log(jQuery(autoCompleteJS.input).parents('.gfield'));
                jQuery(autoCompleteCompany.input).parents('.gfield').addClass('hidden_field');
                // $('#gform_fields_' + GF_PREINSCRIPTION + ' #field_' + GF_PREINSCRIPTION + '_26').prop('aria-required', false);
                jQuery(autoCompleteCompany.input).val('SIRET NON RENSEIGNÉ');
            }

            this.showSiretHiddenFields()
        });

        autoCompleteCompany.input.addEventListener("focus", function (event) {
            jQuery(autoCompleteCompany.input).unmask();
        });
    },

    showSiretHiddenFields () {
        $('#gform_fields_' + GF_PREINSCRIPTION + ' .hidden_field:not(#field_' + GF_PREINSCRIPTION + '_26)')
            .removeClass('hidden_field');
    },

    getUsersData () {
        const autoCompleteJS = new autoComplete({
            selector: '#input_' + GF_PARTICIPANT + '_3', // id input email
            data: {
                // src: this.fakeUserData,
                src: async (query) => {
                    try {
                        return this.getContactsByPrefixEmail(query);
                    } catch (error) {
                        return error;
                    }
                },
                keys: ["email"]
            },

            resultsList: {
                noResults: true,
                maxResults: 10
            },

            resultItem: {
                element: (item, data) => {
                    $(item)
                        .empty()
                        .append('<span class="company">' + data.value.firstname + ' ' + data.value.lastname + '</span>')
                        .append('<span class="address">' + data.value.email + '</span>')
                },
                highlight: true,
            }
        });

        autoCompleteJS.input.addEventListener("selection",  (event) => {
            const feedback = event.detail;

            if (feedback.selection.value) {
                // Prepare User's Selected Value
                const selection = feedback.selection.value[feedback.selection.key];
                const values = feedback.selection.value;

                // Replace Input value with the selected value
                autoCompleteJS.input.value = selection;
                // Fill fields
                this.populateFields(values, GF_PARTICIPANT);
            }
        });
    },

    /**
     * @param {array} _values
     * @param {string} _formId
     */
    populateFields (_values, _formId){
        Object.keys(_values).forEach(key => {
            const fieldType = this.fields.hasOwnProperty(key) ? this.fields[key].type : null;

            if (fieldType) {
                // console.log(`Clé: ${key}, Valeur: ${values[key]}, Type: ${fieldType}`);
                const fields = this.fields[key].field;
                let inputId = "";

                // DYNAMICS_COMPANY > setup.php:648
                const dynamics_company = DYNAMICS_COMPANY;
                // console.log(this.$searchSiret.val(), dynamics_company.siret);
                $.each(fields, (index, elem)=> {
                    if (document.querySelector('#input_' + _formId + '_' + elem)) {
                        inputId = elem;
                        return false;
                    }
                });

                if (inputId) {
                    const $target = $('#input_' + _formId + '_' + inputId);
                    switch (fieldType) {
                        case 'text':
                        case 'select':
                            let decoded = $("<div/>").html(_values[key]).text();
                            $target.val( decoded );
                            if (dynamics_company && (this.$searchSiret.val() === dynamics_company.siret)) {
                                $target.prop('readonly', 'readonly');
                            } else {
                                $target.prop('readonly', false);
                            }
                            break;
                        case 'phone':
                            const phoneInput = eval('gp_advanced_phone_field_' + _formId + '_' + inputId);
                            phoneInput.$telInput.value = _values[key];
                            /*if (dynamics_company && (this.$searchSiret.val() === dynamics_company.siret)) {
                                $(phoneInput.$telInput).prop('readonly', 'readonly');
                            }*/
                            phoneInput.formatInputVal();
                            phoneInput.updateHiddenInputValue();
                            break;
                    }
                    if (dynamics_company && (this.$searchSiret.val() === dynamics_company.siret)) {
                        $target.parents('.ginput_container').eq(0).not('.ginput_container_phone').addClass('ginput_container_readonly');
                    } else {
                        $target.parents('.ginput_container').eq(0).removeClass('ginput_container_readonly');
                    }
                }
            }
        });
    },
    /**
     * format price with thousand separator & replace dot by comma
     */

    formatPriceCurrency (price) {
        // add 2 zeros after comma
        price = price.toFixed(2);
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace('.', ',');
    },

    finalize () {
        $(document).on('load', () => {
            console.log('finalize page-preinscription');

        });
        this.updateEntryOrder ();
        this.updateNumeroEntries();

        const btnClose = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">\n' +
            '  <path d="M12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0ZM12 1.674C9.26137 1.674 6.63492 2.76191 4.69842 4.69842C2.76191 6.63492 1.674 9.26137 1.674 12C1.674 14.7386 2.76191 17.3651 4.69842 19.3016C6.63492 21.2381 9.26137 22.326 12 22.326C14.7386 22.326 17.3651 21.2381 19.3016 19.3016C21.2381 17.3651 22.326 14.7386 22.326 12C22.326 9.26137 21.2381 6.63492 19.3016 4.69842C17.3651 2.76191 14.7386 1.674 12 1.674ZM14.6484 8.2044C14.804 8.06246 15.0084 7.98598 15.2189 7.99087C15.4295 7.99576 15.6301 8.08164 15.7789 8.23065C15.9278 8.37966 16.0135 8.58033 16.0181 8.7909C16.0228 9.00147 15.9461 9.20574 15.804 9.3612L13.17 11.9928L15.804 14.6244C15.9496 14.7793 16.0292 14.9847 16.026 15.1972C16.0228 15.4097 15.9371 15.6126 15.787 15.763C15.6369 15.9134 15.4341 15.9996 15.2216 16.0032C15.0091 16.0068 14.8036 15.9276 14.6484 15.7824L12.012 13.1484L9.378 15.7824C9.24091 15.9199 9.05927 16.0041 8.86571 16.0197C8.67215 16.0353 8.47937 15.9814 8.322 15.8676L8.2212 15.7812C8.14501 15.7054 8.08455 15.6152 8.04329 15.516C8.00203 15.4167 7.9808 15.3103 7.9808 15.2028C7.9808 15.0953 8.00203 14.9889 8.04329 14.8896C8.08455 14.7904 8.14501 14.7002 8.2212 14.6244L10.8552 11.9928L8.2212 9.3612C8.1412 9.28617 8.07711 9.19582 8.03272 9.09553C7.98833 8.99524 7.96455 8.88704 7.96279 8.77738C7.96104 8.66772 7.98134 8.55882 8.0225 8.45716C8.06366 8.3555 8.12484 8.26315 8.20239 8.18559C8.27995 8.10804 8.3723 8.04686 8.47396 8.0057C8.57562 7.96454 8.68452 7.94424 8.79418 7.94599C8.90384 7.94775 9.01204 7.97153 9.11233 8.01592C9.21262 8.06031 9.30297 8.1244 9.378 8.2044L12.012 10.836L14.6484 8.2044Z" fill="#1A1F2F"/>\n' +
            '</svg>';
        /* move modal close button */
        const modalInterval = setInterval( () => {
            if ($('.tingle-modal').length > 0) {
                $('.tingle-modal-box').prepend($('.tingle-modal__close'));
                $('.tingle-modal-box .tingle-modal__closeIcon').html(btnClose);

                clearInterval(modalInterval);
            }
        }, 200);

        this.fieldIsFinanced.trigger('change');

    }
}
