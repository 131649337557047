import { getCleanThematicLabel, getLocalMonth } from '../helpers.js'
export function getCurrentRefinementsWidget(containerId = '[data-widget="current-refinements"]') {
    return window.instantsearch.widgets.currentRefinements({
        container: containerId,
        excludedAttributes: ['thematic'],
        transformItems(items) {
            return items.map((item) => {
                const itemCp = { ...item };

                if (itemCp.refinements && itemCp.refinements.length) {
                    itemCp.refinements.forEach(refinement => {
                        if (refinement.attribute === 'monthStartTs') {
                            try {
                                const ts = parseInt(refinement.label, 10) * 1000;
                                refinement.label = getLocalMonth(new Date(ts));
                            } catch (e) {
                                console.log('Error while translateing unix timestamp into month')
                            }
                        }

                        if (refinement.attribute === 'domains.lvl0') {
                            refinement.label = getCleanThematicLabel(refinement.label);
                        }
                    })
                }

                return itemCp;
            });
        }
    });
}
