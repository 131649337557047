export default class Temoignage {
    constructor() {
        this.temoignages = document.querySelectorAll('.temoignage-list');
        this.init();
    }

    init() {
        console.log('temoignage.init');
        if (this.temoignages.length) {
            this.temoignages.forEach((elt, index) => {
                const swiperElement = elt.querySelector('.swiper-temoignage');

                if (swiperElement) {
                    const pagination = elt.querySelector('.swiper-pagination');
                    pagination.id = `swiper-pagination-${index}`;

                    const swiper = new Swiper(swiperElement, {
                        slidesPerView: 1.01,
                        slidesPerGroup: 1,
                        spaceBetween: 5,
                        grabCursor: true,
                        loop: false,
                        pagination: {
                            el: `#swiper-pagination-${index}`,
                            type: 'bullets',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: elt.querySelector('.swiper-next'),
                            prevEl: elt.querySelector('.swiper-prev'),
                        },
                        breakpoints: {
                            768: {
                                slidesPerView: 'auto',
                                spaceBetween: 20,
                                centeredSlides: true,
                                loop: false,
                                initialSlide: 1,
                            },
                        },
                    });


                    swiper.on('slideChange', function () {
                        const activeIndex = swiper.realIndex;
                        const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');

                        bullets.forEach((bullet) => bullet.classList.remove('swiper-pagination-bullet-active'));

                        if (bullets[activeIndex]) {
                            bullets[activeIndex].classList.add('swiper-pagination-bullet-active');
                        }
                    });
                }
            });
        }
    }
}
