import { collapseButtonText } from './panel.js';

const typesRefinementList = window.instantsearch.widgets.panel({
    templates: {
        header() {
            return 'Type de formation';
        },
        collapseButtonText,
    },
    collapsed: () => false,
})(window.instantsearch.widgets.refinementList);
export const getCourseTypesFilter = (containerId = '[data-widget="course-types"]') => {
    return typesRefinementList(
        {
            container: containerId,
            attribute: 'typeProduct.label',
            sortBy: ['name']
        }
    )
}
