import Tml from "../components/tml.js";
export default {
    init () {
        console.log ('init tml-action-lostpwd');
        const formLostPwd = new Tml('.tml-lostpassword');
        formLostPwd.validateForm();

        const alerts = document.querySelector('.tml-lostpassword .tml-alerts');
        console.log(alerts);

        // observer les alertes pour masquer le formulaire
        let alertsObserver = new MutationObserver(function(mutations) {
            // console.log('mutations', mutations);
            mutations.forEach(function(mutation) {
                if (mutation.addedNodes.length) {
                    for(let node of mutation.addedNodes) {
                        // nous ne suivons que les éléments, nous sautons les autres nœuds (par exemple les nœuds de texte)
                        if (!(node instanceof HTMLElement)) continue;

                        // vérifier que l'élément inséré est un extrait de code
                        if (node.matches('h3')) {
                            // console.log('node', node);
                            $('.tml-lostpassword form').hide();
                        }
                    }
                }
            });
        });

        alertsObserver.observe(alerts, {
            childList: true, // observer les enfants directs
            subtree: true, // et les descendants aussi
            characterDataOldValue: true // transmettre les anciennes données au callback
        });

    }
}
