export function getStatsWidget(containerId = '[data-widget="stats"]', textGenerator) {
    return window.instantsearch.widgets.stats({
        container: containerId,
        templates: {
            text(data, { html }) {
                if (textGenerator) {
                    return textGenerator(html, data);
                }

                return html`${data.nbHits} formation${data.nbHits > 1 && 's'}`;
            },
        },
    });
}
