import { collapseButtonText } from './panel.js';

const durationRefinementList = window.instantsearch.widgets.panel({
    templates: {
        header() {
            return 'Durée';
        },
        collapseButtonText,
    },
    collapsed: () => false,
})(window.instantsearch.widgets.refinementList);

function nameSort(a, b) {
    const customOrder = ['Moins de 1 jour', 'Entre 1 et 5 jours', 'Plus d\'une semaine'];
    return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
}
export const getDurationFilter = (containerId = '[data-widget="duration"]') => {
    return durationRefinementList(
        {
            container: containerId,
            attribute: 'durationRange',
            sortBy: (a, b) => nameSort(a, b)
        }
    )
}
