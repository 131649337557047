export default class VoirPlusImageText {
    constructor() {
        this.blocks = document.querySelectorAll('.fd-block-image-content-cols');
        window.addEventListener('resize', this.handleResize.bind(this));
    }


    init() {
        console.log('VoirPlusImageText.init');

        this.blocks.forEach(block => {
            this.initBlock(block);
        });
    }

    initBlock(block) {
        this.updateContentVisibility(block);

        const moreButton = block.querySelector('.more');

        if (moreButton) {

            moreButton.addEventListener('click', (e) => {
                e.preventDefault();
                const descriptionWrapper = block.querySelector('.description-wrapper');

                // Afficher tout le contenu lorsque le bouton "Voir plus" est cliqué
                descriptionWrapper.classList.toggle('show-more');

                if (descriptionWrapper.classList.contains('show-more')) {
                    // Supprimer la limite de hauteur pour afficher tout le contenu
                    descriptionWrapper.style.maxHeight = 'none';
                    moreButton.querySelector('span').textContent = 'Voir moins';
                } else {
                    // Remettre la limite de hauteur et changer le texte du bouton
                    descriptionWrapper.style.maxHeight = `190px`;
                    moreButton.querySelector('span').textContent = 'Voir plus';
                }
            });
        }
    }

    updateContentVisibility(block) {
        const descriptionWrapper = block.querySelector('.description-wrapper');
        const moreButtonWrapper = block.querySelector('.more');

        if (descriptionWrapper && moreButtonWrapper) {
            const descriptionHeight = descriptionWrapper.scrollHeight;

            // Afficher "Voir plus" si la hauteur de la description est > 190px
            if (descriptionHeight > 190) {
                descriptionWrapper.style.maxHeight = '190px';
                descriptionWrapper.style.overflow = 'hidden';
                moreButtonWrapper.classList.remove('hidden');
                descriptionWrapper.classList.remove('height-less');
                moreButtonWrapper.querySelector('span').textContent = 'Voir plus';
            } else {
                // Cacher le bouton si la description est <= 190px
                moreButtonWrapper.classList.add('hidden');
                descriptionWrapper.classList.add('height-less');
            }
        }
    }

    handleResize() {
        // Lors du redimensionnement, réajuster la visibilité du contenu
        this.blocks.forEach(block => {
            this.updateContentVisibility(block);
        });
    }

    getAdjustedHeight(block) {
        // Renvoie la hauteur de la description
        const descriptionWrapper = block.querySelector('.description-wrapper');
        return descriptionWrapper ? descriptionWrapper.scrollHeight : 0;
    }
}
