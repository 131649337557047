import { collapseButtonText } from './panel.js';

const modalitiesRefinementList = window.instantsearch.widgets.panel({
    templates: {
        header() {
            return 'Modalités';
        },
        collapseButtonText,
    },
    collapsed: () => false,
})(window.instantsearch.widgets.refinementList);

export const getModalitiesFilter = (containerId = '[data-widget="modalities"]') => {
    return modalitiesRefinementList(
        {
            container: containerId,
            attribute: 'modalite.label',
            sortBy: ['name']
        }
    )
}
