export default class Bandeau {
    constructor() {
        this.bandeauContainer = document.querySelector('#bandeau-container');
        this.bandeaux = document.querySelectorAll('.bandeau');
        this.banner = document.querySelector('.banner');
        this.body = document.body;
        this.app = document.querySelector('#app');

        this.basePadding = 0;
        this.updateBasePadding();

        this.init();
    }

    init() {
        console.log('bandeau.init');

        if (this.bandeaux.length) {
            this.bandeaux.forEach((elt) => {
                const bandeauItem = elt.querySelector('.bandeau-item');
                const closeButton = elt.querySelector('.bandeau-item-close');
                const bandeauId = bandeauItem.dataset.id;

                if (!this.isBandeauClosed(bandeauId)) {
                    elt.style.display = 'block';
                }

                if (closeButton) {
                    closeButton.addEventListener('click', () => {
                        this.closeBandeau(elt, bandeauId);
                    });
                }
            });

            this.updateBannerPosition();
            window.addEventListener('resize', this.handleResize.bind(this));
            window.addEventListener('scroll', () => this.handleScroll());
        }
    }

    handleScroll() {
        const currentScroll = window.scrollY;
        const bandeauHeight = this.getBandeauHeight();

        if (currentScroll > this.lastScrollTop) {
            this.bandeauContainer?.classList.remove('fixed');
            this.body.classList.remove('bandeau-fixed');
            this.updateAppPadding(false);

            if (this.banner) {
                this.banner.style.top = '0';
            }
        } else if (currentScroll === 0) {
            this.bandeauContainer?.classList.add('fixed');
            this.body.classList.add('bandeau-fixed');
            this.updateAppPadding(true);

            if (this.banner) {
                this.banner.style.top = `${bandeauHeight}px`;
            }
        }

        this.lastScrollTop = currentScroll;
    }

    updateBannerPosition() {
        const bandeauHeight = this.getBandeauHeight();

        if (this.banner && !this.isBandeauClosed()) {
            this.banner.style.top = window.scrollY === 0 ? `${bandeauHeight}px` : '0';
        }

        if (window.scrollY === 0) {
            this.body.classList.add('bandeau-fixed');
            this.updateAppPadding(true);
        } else {
            this.body.classList.remove('bandeau-fixed');
            this.updateAppPadding(false);
        }
    }

    handleResize() {
        console.log('Resize detected');
        this.updateBasePadding();
        this.updateBannerPosition();
        this.updateAppPadding(this.body.classList.contains('bandeau-fixed'));
    }

    getBandeauHeight() {
        return Array.from(this.bandeaux)
            .filter(bandeau => bandeau.style.display !== 'none')
            .reduce((total, bandeau) => total + bandeau.offsetHeight, 0);
    }

    updateBasePadding() {
        const isMobile = window.innerWidth < 1024;
        this.basePadding = isMobile ? 70 : 145;
        this.updateAppPadding(this.body.classList.contains('bandeau-fixed'));
    }

    updateAppPadding(add) {
        if (this.app) {
            const bandeauHeight = this.getBandeauHeight();
            this.app.style.paddingTop = add ? `${this.basePadding + bandeauHeight}px` : `${this.basePadding}px`;
        }
    }

    isBandeauClosed(id) {
        const closedBandeaux = this.getClosedBandeauxFromCookies();
        return closedBandeaux.includes(id);
    }

    closeBandeau(bandeau, id) {
        bandeau.style.display = 'none';

        const closedBandeaux = this.getClosedBandeauxFromCookies();
        closedBandeaux.push(id);
        this.setClosedBandeauxToCookies(closedBandeaux);
        /* declared in app.blade.php */
        bandeauHeight = 0
        doc.style.setProperty('--app-header-height', `${headerHeight}px`);
        /* end declared in app.blade.php */

        this.updateBannerPosition();
    }

    getClosedBandeauxFromCookies() {
        const cookies = document.cookie.split('; ');
        const closedBandeauxCookie = cookies.find(cookie => cookie.startsWith('bandeaux_fermes='));
        return closedBandeauxCookie ? JSON.parse(decodeURIComponent(closedBandeauxCookie.split('=')[1])) : [];
    }

    setClosedBandeauxToCookies(closedBandeaux) {
        document.cookie = `bandeaux_fermes=${encodeURIComponent(JSON.stringify(closedBandeaux))}; path=/; max-age=${60 * 60 * 24 * 30}`;
    }
}
