export class QueryDispatcher extends EventTarget {
    constructor () {
        super()
    }

    doChange (query) {
        const event = new CustomEvent('ON_CHANGE', { detail: {
                query
            } });

        this.dispatchEvent(event);
    }
}

export function getSuggestions() {
    return window.instantsearch.connectors.connectAutocomplete(
        ({ indices, refine, widgetParams }, isFirstRendering) => {
            let hasHits = false;
            const { container, onSelectChange, queryListener } = widgetParams;

            if (isFirstRendering) {
                queryListener.addEventListener('ON_CHANGE', evtObj => {
                    refine(evtObj.detail.query);
                })
                return;
            }

            container.innerHTML = '';
            indices.forEach(index => {
                if (index.results && index.results.query) {
                    index.results.hits.forEach(hit => {
                        const button = document.createElement('button');

                        hasHits = true;
                        button.setAttribute('class', 'keyword');
                        button.innerText = `${hit.query}`;
                        button.addEventListener('click', (el) => {
                            const query = el.target.innerText;

                            refine(query);
                            onSelectChange({ query });
                        })
                        container.appendChild(button)
                    });
                }
            });
            container.classList.toggle('empty', !hasHits);
            container.classList.toggle('has-hits', hasHits);
        }
    );
}
