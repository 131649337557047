
window.$ = jQuery;
// import 'jquery-ui-bundle/jquery-ui.min.js';
import '../styles/app.scss';

import Router from './util/Router';
import common from './routes/common';
import tmlActionActivate from './routes/tml-action-activate.js';
import tmlActionSignup from './routes/tml-action-signup.js';
import tmlActionLogin from './routes/tml-action-login.js';
import tmlActionLostpassword from './routes/tml-action-lostpwd.js';
import tmlActionResetpass from './routes/tml-action-resetpass.js';
import tmlAction from './routes/tml-action.js';
import singleProduct from './routes/single-product.js';
import pagePreinscription from './routes/page-preinscription.js';
import AlgoliaSearch from './components/algolia-search/algolia-search.js';
import MainSearch from './components/algolia-search/main-search.js';
import VideoLauncher from './components/videos-launcher.js';
import Search from './components/search.js';
import woocommerceEditAccount from './routes/woocommerce-edit-account.js'
import validationDeCommande  from "./routes/checkout.js";

/** Populate Router instance with DOM routes */
const routes = new Router({
    common,
    tmlAction,
    tmlActionLogin,
    tmlActionLostpassword,
    tmlActionSignup,
    tmlActionActivate,
    tmlActionResetpass,
    pagePreinscription,
    singleProduct,
    woocommerceEditAccount,
    validationDeCommande,
});

// Custom elements
customElements.define('video-launcher', VideoLauncher);
customElements.define('algolia-search', AlgoliaSearch);
customElements.define('main-search', MainSearch);

// Load Events
$(document).ready(() => {
    routes.loadEvents();
    new Search().init();
});
