import {
    getCleanThematicLabel,
    getCourseDuration,
    getFormattedDate,
    getFormattedPrices,
    getIntervalDateLiteral
} from '../helpers.js'

export const getCoursesResult = (containerId = '[data-widget="hits"]') => {
    const getTag = ({ label, color }) => {
        return ``;
    }
    return window.instantsearch.widgets.hits({
        container: containerId,
        templates: {
            item (hit, { html, components }) {
                const prices = getFormattedPrices(hit.priceProduct, hit.tarifEarlyBird, (hit.typeProduct && hit.typeProduct.id !== 'L'));
                const dateDebutEarlyBird = hit.dateDebutEarlyBirdTs ? parseInt(hit.dateDebutEarlyBirdTs, 10) * 1000 : false;
                const dateLimiteEarlyBird = hit.dateLimiteEarlyBirdTs ? parseInt(hit.dateLimiteEarlyBirdTs, 10) * 1000 : false;
                const dateEalryBird = getIntervalDateLiteral(dateDebutEarlyBird, dateLimiteEarlyBird);
                const baseURL = '/formation/';
                const thematic = hit.thematicSlug ? `${hit.thematicSlug}/` : '';
                const domainSlug = hit.domainSlug ? `${hit.domainSlug}/` : '';
                const subDomainSlug = hit.subDomainSlug ? `${hit.subDomainSlug}/` : '';
                const productURL = `${baseURL}${thematic}${domainSlug}${subDomainSlug}${hit.slug || ''}`;
                const showPriceContainer = !!hit.reference || !!prices.discountPrice || !!prices.price;
                const qualificationLabel = hit.qualificationLabel || [];
                const qualificationColor = hit.qualificationColor || [];
                const qualifications = [];

                for (const key in qualificationLabel) {
                    if (qualificationColor[key] && qualificationLabel[key]) {
                        qualifications.push({
                            label: qualificationLabel[key],
                            color: qualificationColor[key]
                        })
                    }
                }

                return html`
                <a href="${hit.productLink}" class="hit-container">
                    <div class="info">
                        ${hit.productVideo && html`
                            <video-launcher
                                class="video"
                                data-iframe="${hit.productVideo}"
                            >
                                <div class="image-bg">
                                    <img src="${hit.visuel}" alt="" />
                                </div>
                                <button class="play-button">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14" height="16" fill="none">
                                        <path fill="#fff" d="M13.22 7.436a1 1 0 0 1 0 1.743L1.49 15.777A1 1 0 0 1 0 14.906V1.71A1 1 0 0 1 1.49.838l11.73 6.598Z"></path>
                                    </svg>
                                </button>
                            </video-launcher>`
                        }
                        <div class="about">
                            ${(qualifications.length || hit.reference) && html`
                                <div class="tag-ref-wrapper">
                                    <!-- Tag -->
                                    <div class="tags-wrapper">
                                        ${qualifications.map(qualification => {
                                            return html`
                                                <span class="tag" style="background-color:${qualification.color} ">
                                                    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M12 9.75L12 0.25L-1.90735e-06 9.75L12 9.75Z" fill="#1A1F2F"/>
                                                    </svg>
                                                    ${qualification.label}
                                                </span>
                                            `
                                        })}
                                    </div>
                                    <!-- Ref -->
                                    ${hit.reference && html`<p><span class="ref">Ref : ${hit.reference}</span></p>`}
                                </div>
                            `}
                            <!-- Name - domain -->
                            <div class="name-domain">
                                <p class="h3">${hit.name}</p>
                                ${hit.domains && hit.domains.lvl0 && html`<p class="domain">${Array.isArray(hit.domains.lvl0) ? hit.domains.lvl0.map(domain => getCleanThematicLabel(domain)).join(' / ') : hit.domains.lvl0}</p>`}
                            </div>
                            <!-- Modality - Duration - Location -->
                            <div class="modality-duration-location">
                                ${hit.modalite.label && html`<p class="mdl-line modality">${hit.modalite.label}</p>`}
                                ${getCourseDuration(hit.hourDuration, hit.dayDuration)  && html`<p class="mdl-line duration">${getCourseDuration(hit.hourDuration, hit.dayDuration)}</p>`}
                                ${(hit.place && hit.place.length > 0) && html`<p class="mdl-line place">${Array.isArray(hit.place) ? hit.place.join(' et ') : hit.place}</p>`}
                            </div>
                        </div>
                    </div>
                    ${showPriceContainer && html`
                        <div class="prices-container">
                            ${hit.reference && html`<p><span class="ref">Ref : ${hit.reference}</span></p>`}
                            ${prices && html`
                            <div class="prices">
                                <p class="current-price">${prices.discountPrice || prices.price}</p>
                                ${prices.discountPrice && html`
                                    <p class="old-price">
                                        <span class="stripped-price">${prices.price}</span>
                                        ${dateEalryBird && html`
                                            <br />
                                            <em class="date-ealry-bird">${dateEalryBird}</em>`}
                                    </p>`}
                            </div>
                        `}
                        </div>
                    `}
                </a>
                `
            },
            empty (results, { html }) {
                const hasRefinements = results.getRefinements().length > 0
                const description = hasRefinements
                    ? 'Essayez de réinitialiser vos filtres'
                    : 'Veuillez essayer une autre recherche';

                return html`
                <div class="hits-empty-state">
                    <p class="hits-empty-state-title">Aucun résultat trouvé.</p>
                    <p class="hits-empty-state-description">${description}</p>
                </div>
            `
            },
        },
        hitsPerPage: 10
    })
}
