export default class Videos {
    constructor() {
        this.videos = document.querySelectorAll('.play-button');
    }

    init () {
        console.log('init video js');

        if (this.videos.length > 0) {
            this.videos.forEach((elt, index) => {

                $(elt).on('click', (e) => {

                    let $btn = $(e.currentTarget);
                    let iframe = $('iframe[data-video-id='+ $btn.attr('data-video-id') + ']').prop('outerHTML');
                    iframe = iframe.replace('data-src="', 'src="');
                    $('.popin-video .oembed-responsive').append(iframe);

                    this.showVideo();
                    return false;
                })
            });
        }

        $('.popin-video .btn-close').on('click', (e) => {
            this.hideVideo();
            return false;
        });
    }

    showVideo () {
        $('html').addClass('popin-video-open');
    }

    hideVideo () {
        $('.popin-video .oembed-responsive iframe').remove();
        $('html').removeClass('popin-video-open');
    }
}
