export default class VoirPlusText {
    constructor() {
        this.intervenants = document.querySelectorAll('.intervenant-item');
    }

    init() {
        if (this.intervenants.length) {
            this.intervenants.forEach((intervenant) => {
                const showMoreButton = intervenant.querySelector('.show-more-btn');
                const description = intervenant.querySelector('.intervenant-description');
                const enSavoirPlusButton = intervenant.querySelector('.en-savoir-plus-btn');

                const adjustDescription = () => {
                    if (!description) return; // Vérifier si description existe avant d'accéder à ses propriétés

                    if (description.scrollHeight > description.clientHeight) {
                        if (showMoreButton) showMoreButton.style.display = 'flex';
                        if (enSavoirPlusButton) enSavoirPlusButton.style.display = 'none';
                    } else {
                        if (showMoreButton) showMoreButton.style.display = 'none';
                        if (enSavoirPlusButton) enSavoirPlusButton.style.display = 'block';
                    }
                };

                adjustDescription();

                if (showMoreButton && description) {
                    showMoreButton.addEventListener('click', () => {
                        if (!description.classList.contains('expanded')) {
                            description.classList.add('expanded');
                            showMoreButton.style.display = 'none';
                            if (enSavoirPlusButton) enSavoirPlusButton.style.display = 'block';
                        }
                    });
                }

                window.addEventListener('resize', adjustDescription);
            });
        }
    }
}
