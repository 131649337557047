export default class Header {
    /**
     * Constructor
     */
    constructor() {
        this.$btnHumberger = $('.menu-burger');
        this.$bannerPrincipal = $('.banner-principal');
        this.$bannerSubmenu = $('.banner-submenu');
        this.$navLinks = $('.banner-principal-nav-link');
        this.$megaMenus = $('.mega-menu');
        this.$backLinks = $('.mega-menu-back-link');
        this.$btnForm = $('.btn-form');
        this.$menuAccount = $('.woocommerce-account-navigation');
        this.breakpoint = 1024;
        this.$cartCount = $('.cart-count');
    }

    /**
     * Initialisation
     */
    init() {
        console.log('header.init');
        const cartCount = parseInt($('.cart-count').text());

        this.$navLinks.on('click', (event) => {
            if (!this.preventClick) { // Ne pas gérer le clic si le focus l'a déjà fait
                this.toggleMegaMenu(event);
            }
            this.preventClick = false; // Réinitialiser le drapeau après le clic
        });

        this.$navLinks.on('focus', (event) => {
            event.preventDefault();
            this.handleNavLinkFocus(event);
            this.preventClick = true; // Empêcher le clic d'être traité immédiatement après le focus
        });

        // Appliquer la gestion du clic uniquement si la largeur de la fenêtre est supérieure ou égale à 1024 pixels
        if (window.innerWidth >= this.breakpoint) {
            $(document).on('click', (event) => this.closeMegaMenuOnClickOutside(event));
        }

        this.$btnHumberger.on('click', (event) => this.toggleMenuPrincipal(event));
        this.$backLinks.on('click', (event) => this.handleBackLinkClick(event));

        $(window).on('resize', () => {
            if (window.innerWidth >= this.breakpoint) {
                $(document).on('click', (event) => this.closeMegaMenuOnClickOutside(event));
            } else {
                $(document).off('click', (event) => this.closeMegaMenuOnClickOutside(event));
            }
        });

        /* popin newsletter */
        this.$btnForm.on('click', (e) => {
            e.preventDefault();
            let btn = $(e.currentTarget);
            let popin = btn.data('popin');
            $('.' + popin).addClass('popin-form-open');
            $('html, body').addClass('popin-open')
            return false;
        });

        $('.popin-form .btn-close').on('click', (e) => {
            $(e.currentTarget).closest('.popin-form-open').removeClass('popin-form-open');
            $('html, body').removeClass('popin-open')
            return false;
        });

        if (this.$menuAccount.length > 0) {
            $('.btn-menu-mobile', this.$menuAccount).on('click', (e) => {
                e.preventDefault();
                let btn = $(e.currentTarget);
                let menu = btn.closest('.woocommerce-account-navigation');
                menu.toggleClass('open');
                $('html').toggleClass('menu-account-open');
                return false;
            });
        }

        if (cartCount > 0) {
            this.$cartCount.css('visibility', 'visible');
        }

        this.showPopinSwitch();
    }

    toggleMegaMenu(event) {
        event.preventDefault();

        console.log('=== toggleMegaMenu ===');
        const $link = $(event.currentTarget);
        const targetMenuId = $link.data('target');
        const $targetMenu = $(`#${targetMenuId}`);
        const $arrow = $link.find('.arrow');

        $('body').addClass('open');

        if ($targetMenu.hasClass('active')) {
            $targetMenu.removeClass('active');
            $arrow.removeClass('rotate');
            $('body').removeClass('open');
        } else {
            this.$megaMenus.removeClass('active');
            this.$navLinks.find('.arrow').removeClass('rotate');
            if ($targetMenu.length) {
                $targetMenu.addClass('active');
                $arrow.addClass('rotate');
            }
        }
    }

    handleNavLinkFocus(event) {
        this.toggleMegaMenu(event);
    }

    closeMegaMenuOnClickOutside(event) {
        const isClickInsideMenu = $(event.target).closest('.banner-principal-nav-link').length > 0 || $(event.target).closest('.mega-menu').length > 0;

        if (!isClickInsideMenu) {
            this.$megaMenus.removeClass('active');
            this.$navLinks.find('.arrow').removeClass('rotate');
            this.$navLinks.removeClass('focus');
            $('body').removeClass('open');
        }
    }

    toggleMenuPrincipal(event) {
        event.preventDefault();

        const isMenuOpen = this.$btnHumberger.hasClass('open');

        this.$btnHumberger.toggleClass('open');
        this.$bannerPrincipal.toggleClass('open');
        $('html, body').toggleClass('open');

        if (isMenuOpen) {
            this.$megaMenus.removeClass('active');
            this.$navLinks.find('.arrow').removeClass('rotate');
            $('body').removeClass('open');
        }
    }

    handleBackLinkClick(event) {
        event.preventDefault();
        this.$megaMenus.removeClass('active');
    }

    /**
     * show switch to blog popin
     */

    showPopinSwitch() {
        const $cardPress = $('.card-presse-link');
        const $btnConfirm = $('.btn-confirm');
        const $popin = $('.popin-switch');
        let targetLink = null;

        $cardPress.each((index, card) => {
            $(card).on('click', (e) => {
                e.preventDefault();

                targetLink = e.currentTarget.href;
                console.log(targetLink);
                if(targetLink.indexOf('#') === -1) {
                    $btnConfirm.attr('href', targetLink);
                    $popin.addClass('popin-form-open');
                    $('html, body').addClass('popin-open');
                }
                return false;
            });
        });
    }
}
