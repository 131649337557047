import { collapseButtonText } from './panel.js';

const qualificationRefinementList = window.instantsearch.widgets.panel({
    templates: {
        header() {
            return 'Autres';
        },
        collapseButtonText,
    },
    collapsed: () => false,
})(window.instantsearch.widgets.refinementList);

export const getQualificationFilter = (containerId = '[data-widget="qualification"]') => {
    return qualificationRefinementList(
        {
            container: containerId,
            attribute: 'qualificationLabel',
            sortBy: ['name']
        }
    )
}
