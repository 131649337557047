import TmlPwdValidation from "../components/tml-pwd-validation.js";
import tmlAction from "./tml-action.js";

export default {
    init () {
        console.log ('init woocommerce edit account');
        const formLogin = new TmlPwdValidation();
        tmlAction.init();

        /*
         * Make all input text to readonly
         */

        jQuery('input[type="text"], input[type="tel"], input[type="email"]', '.form-infos-perso').each((index, input) => {
            jQuery(input).attr('readonly', 'readonly');
        });

        jQuery('select', '.form-infos-perso').each((index, select) => {
            jQuery(select).attr('disabled', true);
        });

        jQuery('.ginput_container', '.form-infos-perso').each((index, container) => {
            jQuery(container).addClass('ginput_container_readonly');
        });

        console.log('phone', jQuery('#input_9_7_raw'));
    }
}

