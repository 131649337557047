export default class ProductDownloadProgram {
    constructor() {
        this.$btnDownloadProgram = $('.download-btn');
        this.$inputProgramLink = $('.gform_hidden[name=input_26]');
    }

    init() {
        console.log('product download init', this.$inputProgramLink);

        if (this.$btnDownloadProgram.length && this.$inputProgramLink.length) {
            this.$btnDownloadProgram.on('click', (event) => this.handleDownloadProgram(event));
        }
    }

    handleDownloadProgram(event) {
        event.preventDefault();
        const $btn = $(event.currentTarget);
        const programLink = $btn.data('link');
        this.$inputProgramLink.val(programLink);
        console.log('handleDownloadProgram', programLink, this.$inputProgramLink);
        return false;
    }
}
