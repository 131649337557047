export default class NavigationSingleProduct {
    constructor() {
        this.productNav = document.querySelector('.product-sticky-nav');
        this.sections = document.querySelectorAll('.single-product-content-body section[id^="section-"]');
        this.stickyHeader = document.querySelector('.banner');
        this.heroSection = document.querySelector('.single-product-content-hero');
        this.stickyEls = document.querySelectorAll('.block--sticksyJs');
        this.offsetTrigger = 120;
        this.init();
    }

    init() {
        console.log('navigation-single-product.init');

        if (!this.productNav || !this.sections || !this.stickyHeader || !this.heroSection) return;

        this.filterNavLinks();
        this.handleScroll();
        this.setActiveNavItem();
        window.addEventListener('scroll', () => {
            this.handleScroll();
            this.setActiveNavItem();
        });

        this.productNav.querySelectorAll('a').forEach(link => {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const targetId = link.getAttribute('href').substring(1);
                const targetSection = document.getElementById(targetId);

                if (targetSection) {
                    const navHeight = this.productNav.offsetHeight - 35;
                    const sectionTop = targetSection.offsetTop;

                    window.scrollTo({
                        top: sectionTop - navHeight,
                        behavior: 'smooth'
                    });
                }
            });
        });
    }

    filterNavLinks() {
        this.productNav.querySelectorAll('a').forEach(link => {
            const sectionId = link.getAttribute('href').replace('#', '');
            const section = document.getElementById(sectionId);

            if (!section) {
                const listItem = link.closest('li');
                if (listItem) {
                    listItem.style.display = 'none';
                }
            }
        });
    }

    handleScroll() {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const heroBottom = this.heroSection.getBoundingClientRect().bottom + window.scrollY - this.offsetTrigger;

        if (scrollPosition > heroBottom) {
            this.productNav.classList.add('active');
            this.stickyHeader.classList.add('hidden');
            this.stickyEls.forEach((stickyEl) => {
                if(stickyEl.style.position == "fixed") {
                    stickyEl.style.top = "75px";
                } else {
                    stickyEl.style.top = "auto";
                }
            });
        } else {
            this.productNav.classList.remove('active');
            this.stickyHeader.classList.remove('hidden');

            this.stickyEls.forEach((stickyEl) => {
                if(stickyEl.style.position == "fixed") {
                    stickyEl.style.top = "165px";
                }
            });
        }
    }

    setActiveNavItem() {
        const scrollPosition = window.scrollY || window.pageYOffset;

        this.sections.forEach(section => {
            const sectionTop = section.offsetTop - this.productNav.offsetHeight;
            const sectionBottom = sectionTop + section.offsetHeight;
            const sectionId = section.getAttribute('id');

            if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                this.productNav.querySelectorAll('a').forEach(link => {
                    link.classList.remove('active');
                    if (link.getAttribute('href').includes(sectionId)) {
                        link.classList.add('active');
                    }
                });
            }
        });
    }
}
