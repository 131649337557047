import $ from 'jquery';

export default class ProductAside {
    constructor() {}

    init() {
        console.log('init sticky fiche produit');
        // this.productSticky();
        this.sessionSelectHandler();
        this.stickyRegisterButtonHandler();
        this.toggleVoirPlusHandler();
        console.log('end sticky fiche produit');
    }

    // productSticky() {
    //     const stickyEls = document.querySelectorAll('.block--sticksyJs');
    //     if (stickyEls && stickyEls.length > 0) {
    //         stickyEls.forEach((stickyEl) => {
    //             new Sticksy(stickyEl, {
    //                 topSpacing: 200,
    //             });
    //         });
    //     }
    // }

    sessionSelectHandler() {
        const $sessionSelect = $('.session-select');
        const $sessionContents = $('.session-content');
        const $sessionPrices = $('.session-price');
        const $defaultPrice = $('.default-price');
        const $sessionIdHidden = $('#input_6_23'); // hidden field for session-id
        const $sessionIdHidden2 = $('#input_6_24'); // Only if the hidden field is not 23
        var sessionInputFound;

        if($sessionIdHidden.hasClass('gform_hidden')){
            sessionInputFound = $sessionIdHidden;
        }else{
            sessionInputFound = $sessionIdHidden2;
        }

        if ($sessionSelect.length && $sessionContents.length && $sessionPrices.length && $defaultPrice.length) {

            $sessionContents.hide();
            $sessionPrices.hide();
            $defaultPrice.show();

            $sessionSelect.on('change', function () {
                const selectedValue = $(this).val();
                const $selectedSessionContent = $sessionContents.filter(`[data-session-reference="${selectedValue}"]`);
                let selectedOption = $(this).find(':selected');
                const sessionId = selectedOption.data('session-id');
                const programDetailForm = selectedOption.data('form-id');
                const $sessionIdInput = $('#input_' + programDetailForm + '_24');
                const $selectedSessionPrice = $sessionPrices.filter(`[data-session-reference="${selectedValue}"]`);

                sessionInputFound.val(sessionId);

                $sessionContents.hide();
                $sessionPrices.hide();

                if (selectedValue) {
                    $defaultPrice.hide();
                    $selectedSessionContent.show();
                    $selectedSessionPrice.show();
                    $sessionIdInput.val(sessionId);
                } else {
                    $defaultPrice.show();
                }
            });

            $sessionSelect.on('focus', function () {
                $(this).addClass('select-open');
            });

            $sessionSelect.on('blur', function () {
                $(this).removeClass('select-open');
            });

            if ($sessionSelect[0].options.length === 2) {
                const uniqueValue = $sessionSelect[0].options[1].value;
                $sessionSelect.val(uniqueValue).trigger('change');
            }

        }
    }

    stickyRegisterButtonHandler() {
        const $btnPreInscription = $('.btn-pre-inscription');
        const $btnAddToCart = $('.single-product-aside-body .btn-add-to-cart');
        const $stickyButton = $('#sticky-register-button');
        $stickyButton.hide();

        if (!$btnPreInscription.length && !$btnAddToCart.length) {
            return;
        }

        if (!$stickyButton.length) {
            return;
        }

        let $targetButton = null;
        if ($btnPreInscription.length) {
            $targetButton = $btnPreInscription;
        } else if ($btnAddToCart.length) {
            $targetButton = $btnAddToCart;
        }

        if ($targetButton.length && $stickyButton.length) {
            function checkSidebarPosition() {
                const targetPosition = $targetButton[0].getBoundingClientRect().top;

                if (targetPosition < 0) {
                    $stickyButton.show();
                } else {
                    $stickyButton.hide();
                }
            }

            function scrollToSidebar(event) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: $targetButton.offset().top - 150
                }, 150);
            }

            $(window).on('scroll', checkSidebarPosition);
            $stickyButton.on('click', scrollToSidebar);

            checkSidebarPosition();
        }
    }

    toggleVoirPlusHandler() {
        const $voirPlusButtons = $('.voir-plus-module');

        $voirPlusButtons.each(function () {
            $(this).on('click', function (event) {
                event.preventDefault();

                const $list = $(this).siblings('.module-list');
                const $hiddenItems = $list.find('.module-list-item.hidden');

                if ($hiddenItems.length) {
                    $hiddenItems.removeClass('hidden');
                    $(this).addClass('active');
                    $(this).find('.label').text('Voir moins');
                } else {
                    $list.find('.module-list-item').each(function (index) {
                        if (index >= 2) {
                            $(this).addClass('hidden');
                        }
                    });
                    $(this).find('.label').text('Voir plus');
                    $(this).removeClass('active');
                }
            });
        });
    }
}
